import React, { useState, useEffect } from 'react';
import logo from './assets/logo-icons/Icon-8.png';
// import backgroundImage from './assets/UI/Backgrounds/Welcome/Gluten-free@3x.jpg';
import './App.css';
import './index.css';
import { Route, Link } from 'react-router-dom';

function TermsOfUse() {

 

  useEffect(function() {
    
  });


  const [button0Style, setButton0Style] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [button1Style, setButton1Style] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [buttonStyleNormal] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    borderWidth: 1,
    fontSize: 20,
  });

  const [buttonStyleHover] = useState({
    backgroundColor: 'white',
    border: '0px',
    // borderTopWidth: 0,
    // borderLeftWidth: 0,
    // borderBottomWidth: 0,
    color: '#81CFF0',
    height: 33,
    width: 200,
    borderWidth: 1,
    fontSize: 20,
  });

  function mouseHover(buttonIndex) {
    if(buttonIndex === 0) {
      setButton0Style(buttonStyleHover);
    }
    else if(buttonIndex === 1) {
      setButton1Style(buttonStyleHover);
    }
  };

  function mouseOut(buttonIndex) {
    if(buttonIndex === 0) {
      setButton0Style(buttonStyleNormal);
    }
    else if(buttonIndex === 1) {
      setButton1Style(buttonStyleNormal);
    }
  };

  return (
    <div style={styles.container}>
      <title>Nüri | Terms of Use</title>
      <div style={{paddingBottom: 0, height: 'auto', width: '100%', border: '0px solid red', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
        <p style={{fontWeight: 'bold', textAlign: 'center', width: '80%', margin: 0, marginTop: 30}}  class={'main'}>TERMS OF USE</p>
        <p style={{textAlign: 'left', width: '80%'}}>
        WELCOME TO NÜRI. PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING
        THIS APPLICATION AND/OR THE WEBSITE.<br/>
        Your use of Nüri is expressly conditioned on your acceptance without modification of the following
        Terms of Use (the “Terms of Use”). If you accept these Terms of Use, click on the “I Accept” button
        below. That action is the equivalent of your signature and indicates your acceptance of these Terms of
        Use and that you intend to be legally bound by them.<br/><br/>

        Acceptance of Terms of Use<br/><br/>

        This is an agreement between you and Nüri Inc. (“Nüri”) regarding your access to and use of the
        mobile application “Nüri”, the Nüri website and any content posted and/or linked to either of the
        foregoing (collectively, the “App”). These Terms of Use also provide benefits to Nüri’s employees,
        agents, affiliates, contractors, service providers, suppliers and other persons, and those benefits
        (including, without restricting the generality of the foregoing, limitations on liability) extend to those
        persons, and where such benefits are provided, the term “Nüri” shall be construed as including those
        persons.<br/><br/>
        Nüri may, in its sole discretion, which need not be reasonable, change these Terms of Use from time
        to time as it relates to future use of the App, by posting revised Terms of Use on the App. By using the
        App after these revised Terms of Use have been posted, you signify your acceptance and agreement
        to be bound by the revised Terms of Use. You may not change the Terms of Use in any manner.<br/><br/>

        Services, Payment and Registration<br/><br/>

        The App will allow you to, among other things, (i) offer for sale food products which you have prepared
        or will prepare; (ii) purchase such food products from others; and/or (iii) deliver such food products to
        others (the “Services”) using a wireless telecommunication computing device or other compatible
        device. Nüri does not buy or sell food, but provides a marketplace in which you can buy food
        from, and sell food to, others. Nüri charges a fee for the use of this marketplace by retaining a
        percentage of the total amount of each transaction that takes place through the App.<br/><br/>
        In order to access the Services, you must create an account via the App. In order to create an
        account, you will be required to provide certain personal information, all of which is subject to the Nüri
        Privacy Policy as discussed below under the heading “Privacy”. You must provide accurate and
        complete information in when creating your account. We reserve the right to suspend or terminate
        your account if you provide inaccurate or incomplete information, intentionally or otherwise, or fail to
        comply with the account creation requirements, intentionally or otherwise.<br/><br/>
        Nüri uses a third party payment processing provider (the “Payment Processor”) in order to facilitate
        the payment of amounts owing by purchasers of food products to those providing such food products.
        When you provide us with your information for payment processing, that information, along with other
        relevant personal information about you, is provided to the Payment Processor for the purposes of
        facilitating such payment. You hereby consent to the collection, use and disclosure of your personal
        information by and to the Payment Processor for the foregoing purposes. You further acknowledge
        and agree that the Payment Processor may also collect your personal information and/or other
        information about you and the collection and use of such information will be subject to the terms of
        such Payment Processor’s privacy policy, if any. You acknowledge and agree that we shall have no
        liability to you in connection with the use and disclosure of your personal information when collected
        by the Payment Processor.<br/><br/>

        Use of Site<br/><br/>

        You may only use the App and its content in the manner outlined in the App and in these Terms of
        Use and in no other manner. Neither the App nor its content may be, in whole or in part, copied,
        reproduced, republished, uploaded, posted, transmitted or distributed without the prior written
        permission of Nüri. Unauthorized use of the App and/or the content contained on the App may violate
        applicable copyright, trademark or other intellectual property laws or other laws (the word “law” as
        used herein includes regulations and other subsidiary legislation). Except as expressly authorized
        herein, you are not permitted to engage in any resale or commercial use of the App or its contents;
        any framing, embedding, derivative or other use of the App or its contents; any downloading or
        copying of content or information from the App; or any use of data mining, robots, or similar data
        gathering and extraction tools on the App.<br/><br/>

        You are prohibited from contributing, posting or transmitting to, by or through this App any infringing,
        unlawful, threatening, libelous, defamatory, offensive, obscene, indecent, inflammatory, pornographic
        or profane content or any content that could constitute or encourage conduct that would be considered
        a criminal offence, give rise to civil liability, or otherwise violate any law, or any content consisting of or
        containing software viruses, malicious exploits or other malicious code, political campaigning or
        opinion, commercial solicitation, chain letters, mass mailings, or any form of “spam.” You may not use
        a false e-mail address, impersonate any person or entity, or otherwise mislead as to the origin of any
        content. Nüri reserves the right (but does not have any obligation) to remove or edit such content
        including, but not limited to, any such content included in your profile for use on the App, and to edit
        your content generally.<br/><br/>
        In its sole discretion, which need not be reasonable, in addition to any other rights or remedies
        available to Nüri and without any liability whatsoever whether in tort, contract, or any other theory of
        law, equity or civil law, Nüri at any time and without notice may terminate or restrict your access to all
        or any component of the App without cause or justification.
        Nüri’s failure or delay to enforce any of the Terms of Use shall not operate as a waiver of any of its
        rights and privileges contained herein.<br/><br/>
        You acknowledge and understand that, in respect of your use of the App, you will be engaging
        in activities that involve risk of damage to personal property or serious illness and/or bodily
        injury, including permanent disability and death, and severe social and economic losses which
        might result not only from your own actions, inactions or negligence, but the actions, inactions
        or negligence of others, including, without restricting the generality of the foregoing
        (regardless of any pre-existing vulnerability):<br/><br/>

         allergic reaction to unlisted food ingredients;<br/>
         choking;<br/>
         injury due to foreign or native objects embedded in food;<br/>
         food-borne illness;<br/>
         burns;<br/>
         dental, tongue, palatal or similar injury;<br/>
         injury from the use of appliances or other cooking equipment, food service
        equipment, food containers or utensils;<br/>
         injury from motor vehicle or other means of transportation of any kind;<br/>
         arrest or prosecution for unlicensed operation of a food service establishment;<br/>
         psychiatric or psychological injury resulting from use of the App, including, but
        not limited to, inability to order, unavailability of items, lateness of food or
        disappointment in taste of food or food quality; and/or<br/>
         physical or psychological assault by third parties who are dissatisfied by food
        for any reason;<br/>
        (collectively, the “Risks”), and that the Risks involve not only you but others. YOU
        ARE VOLUNTARILY ASSUMING THESE RISKS WITH FULL KNOWLEDGE THEREOF.<br/><br/>

        Ownership, Trademarks and Copyright<br/><br/>

        The App, its design, all text, graphics, content, video, audio and the selection and arrangement of the
        App and its content are the property of Nüri, and/or its subsidiaries, affiliates, third party providers and
        distributors (“Third Parties”), and are protected under the copyright laws of Canada and other
        countries. None of the content found on the App may be reproduced, republished, distributed,
        displayed, sold, transferred, or modified without the express written permission of Nüri or the
        applicable Third Parties. All software used in the App is the property of Nüri or its software suppliers
        and protected by Canadian and international copyright laws.<br/><br/>
        The name ‘Nüri’ and all other related trademarks and design marks displayed on the App are
        registered and/or common law trademarks of Nüri. Other trademarks and design marks appearing on
        the App are trademarks of their respective owners. Nothing contained on the App should be construed
        as granting, by implication or otherwise, any license or right to use or disparage any trademarks.<br/><br/>

        Customer Content<br/><br/>

        By submitting information in any form, including without limitation text, audio, video and image, in
        connection with your use of the App (“Customer Content”) you automatically grant, and you
        represent and warrant that you have the right to grant, to Nüri a worldwide, exclusive, royalty-free,
        perpetual, irrevocable, and fully sub-licensable right to do with the Customer Content anything that
        you could do, including, without restricting generality of the foregoing, use, delete, reproduce, modify,
        adapt, publish, translate, create derivative works from, distribute, display and commercialize any such
        Customer Content throughout the world in any media, now known or later developed, in any way and
        for any purpose whatsoever, commercial or otherwise, without providing compensation to you or any
        other person, without any liability whatsoever, whether in tort, contract or any other theory of law,
        equity or civil law, and free from any obligation of confidence, and you waive any and all moral rights
        in the Customer Content.<br/><br/>

        You represent, warrant and covenant that you are the owner all right, title and interest to all Customer
        Content. If Customer Content or any particular Customer Content was captured, recorded or
        otherwise created by someone else, you agree to obtain and retain a written assignment of rights and
        waiver of moral rights from such person. You agree to produce an executed copy of such assignment
        and waiver to Nüri upon request.<br/><br/>

        Username and Password<br/><br/>

        Your account on the App may be accessed only by use of your login name and password. You will
        choose your own username and password upon registration for the App. You are solely responsible
        and liable for any use and/or misuse of your login name and password and for all activities that occur
        under your login name and password. For security reasons, you must keep your login name and
        password confidential and not disclose them to any person or permit any other person to use them,
        except an authorized representative of Nüri.<br/><br/>
        All login names and passwords remain the property of Nüri, and may be cancelled or suspended at
        any time by Nüri without any notice or liability, whether in tort, contract or any other theory of law,
        equity or civil law, to you or any other person. Nüri is not under any obligation to verify the actual
        identity or authority of the user of any login name or password.<br/><br/>

        You must immediately notify Nüri of any unauthorized use of your login name or password, or if you
        know or suspect that your login name or password has been lost, stolen, has become known to any
        other person, or has been otherwise compromised.<br/><br/>

        Privacy<br/><br/>

        You have read Nüri’s Privacy Policy, the terms of which appear on the App and are incorporated into
        these Terms of Use, and you agree that the terms of that policy are reasonable. You consent to the
        collection, use and disclosure of your personal information by Nüri in accordance with the terms of and
        for the purposes set out in Nüri’s Privacy Policy.<br/><br/>

        LIABILITY DISCLAIMER – READ THE FOLLOWING CAREFULLY
        NOTWITHSTANDING ANYTHING ELSE IN THIS AGREEMENT, NÜRI MAKES NO
        REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, REGARDING
        THE APP AND/OR ANY CONTENT OR SERVICES PROVIDED ON THE APP, ALL OF WHICH ARE
        PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. NÜRI DOES NOT WARRANT THE
        ACCURACY, COMPLETENESS, CURRENCY, RELIABILITY OR SUITABILITY OF THE
        OPERATION OF THE APP, OR ANY OF THE CONTENT OR DATA FOUND ON THE APP, AND
        EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS IN RESPECT OF THE APP, ITS
        CONTENT, INFORMATION OR DATA, AND ANY SERVICES OR PRODUCTS OFFERED ON THE
        APP, INCLUDING THOSE ARISING BY STATUTE OR OTHERWISE IN LAW, INCLUDING,
        WITHOUT RESTRICTING THE GENERALITY OF THE FOREGOING, ANY WARRANTIES OF
        MERCHANTABLE QUALITY, FITNESS FOR PURPOSE, TITLE OR NON-INFRINGEMENT.
        NÜRI IS NOT RESPONSIBLE FOR LATE, LOST, INCOMPLETE, ILLEGIBLE, MISDIRECTED,
        MISREPRESENTED OR STOLEN MESSAGES, MAIL OR OTHER DATA OF ANY KIND OR
        NATURE WHATSOEVER, UNAVAILABLE NETWORK CONNECTIONS, FAILED, INCOMPLETE,
        GARBLED OR DELAYED COMPUTER TRANSMISSIONS, ON-LINE FAILURES, HARDWARE,
        SOFTWARE OR OTHER TECHNICAL MALFUNCTIONS OR DISTURBANCES OR ANY OTHER
        COMMUNICATIONS FAILURES OR CIRCUMSTANCES AFFECTING, DISRUPTING OR
        CORRUPTING COMMUNICATIONS.<br/><br/>
        NÜRI ASSUMES NO RESPONSIBILITY, AND WILL NOT BE LIABLE, WHETHER IN TORT,
        CONTRACT OR ANY OTHER THEORY OF LAW, EQUITY OR CIVIL LAW, FOR ANY DAMAGES
        TO, OR ANY VIRUSES AFFECTING YOUR COMPUTER EQUIPMENT (INCLUDING A WIRELESS
        TELECOMMUNICATION COMPUTING DEVICE) OR OTHER PROPERTY ON ACCOUNT OF YOUR
        ACCESS TO, USE OF, OR BROWSING ON THE APP OR YOUR DOWNLOADING OF ANY
        MATERIALS, TEXT, IMAGES, VIDEO, AUDIO OR OTHER DATA FROM THE APP, REGARDLESS
        OF WHETHER OR NOT NÜRI KNEW OR SHOULD HAVE FORESEEN OR KNOWN OF SAME.
        NÜRI ASSUMES NO RESPONSIBILITY, AND WILL NOT BE LIABLE, WHETHER IN TORT,
        CONTRACT OR ANY OTHER THEORY OF LAW, EQUITY OR CIVIL LAW, FOR THE RISKS OR
        ANY AND ALL LOSS, DAMAGE, EXPENSE (MEDICAL OR OTHERWISE), ILLNESS OR INJURY,
        INCLUDING DEATH OR PERMANENT DISABILITY, THAT YOU OR A THIRD PARTY MAY
        SUFFER, OR THAT YOUR HEIRS OR NEXT OF KIN, OR THOSE OF ANY THIRD PARTY MAY
        SUFFER, AS A RESULT OF THE RISKS OR YOUR USE OF THE APP INCLUDING, WITHOUT
        LIMITATION, YOUR CONSUMPTION, PREPARATION, HANDLING OR TRANSPORTATION OF
        FOOD OR BEVERAGES OF ANY KIND IN CONJUNCTION WITH OR IN ANY WAY CONNECTED
        TO YOUR USE OF THE APP. FOR GREATER CERTAINTY, AND WITHOUT RESTRICTING THE
        GENERALITY OF THE FOREGOING, THIS EXCLUSION OF LIABILITY OPERATES WITH
        RESPECT TO THE RISKS DEFINED ABOVE.<br/><br/>

        Release<br/><br/>

        YOU HEREBY RELEASE, WAIVE, DISCHARGE AND COVENANT NOT TO SUE NÜRI, ITS
        PARTNERS, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS AND REPRESENTATIVES OF
        ANY OF THE FOREGOING OR THEIR RESPECTIVE AFFILIATES (COLLECTIVELY, THE
        “RELEASEES”) FROM ANY AND ALL LIABILITY TO YOU, YOUR HEIRS AND NEXT OF KIN FOR
        ANY AND ALL CLAIMS, DEMANDS, LOSSES OR DAMAGES ON ACCOUNT OF INJURY,
        INCLUDING PERMANENT DISABILITY, DEATH OR DAMAGE TO PROPERTY (COLLECTIVELY,
        “CLAIMS”) CAUSED OR ALLEGED TO BE CAUSED IN WHOLE OR IN PART BY THE
        RELEASEES NEGLIGENCE, BREACH OF ANY DUTY OF CARE, INCLUDING ANY STATUTORY
        DUTY, BREACH OF CONTRACT, OR ANY OTHER ACT OF THE RELEASEES IN RESPECT OF,
        THE APP OR YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, YOUR
        CONSUMPTION, PREPARATION, HANDLING OR TRANSPORTATION OF FOOD OR
        BEVERAGES OF ANY KIND IN CONJUNCTION WITH OR IN ANY WAY CONNECTED TO YOUR
        USE OF THE APP. FOR GREATER CERTAINTY, AND WITHOUT RESTRICTING THE
        GENERALITY OF THE FOREGOING, THIS RELEASE OPERATES WITH RESPECT TO THE
        RISKS DEFINED ABOVE.<br/><br/>

        Maximum Liability<br/><br/>

        IF NÜRI SHOULD BE FOUND LIABLE FOR ANY LOSS OR DAMAGE WHICH ARISES OUT OF OR
        IS IN ANY WAY CONNECTED WITH ANY OF THE FUNCTIONS OR USES OF THE APP OR ITS
        CONTENT, OR ANY OF THE SERVICES OFFERED ON THE APP, INCLUDING, WITHOUT
        LIMITATION, YOUR CONSUMPTION, PREPARATION, HANDLING OR TRANSPORTATION OF
        FOOD OR BEVERAGES OF ANY KIND IN CONJUNCTION WITH OR IN ANY WAY CONNECTED
        TO YOUR USE OF THE APP, THE ABSOLUTE MAXIMUM LIABILITY OF NÜRI WILL IN NO
        EVENT EXCEED IN THE AGGREGATE $20 CAD. FOR GREATER CERTAINTY, AND WITHOUT
        RESTRICTING THE GENERALITY OF THE FOREGOING, THIS LIMITATION OF LIABILITY
        OPERATES WITH RESPECT TO THE RISKS DEFINED ABOVE.<br/><br/>
        IN NO EVENT WILL NÜRI BE LIABLE FOR ANY INJURY, LOSS, CLAIM, DAMAGE, OR ANY
        SPECIAL, EXEMPLARY, PUNITIVE, DIRECT, INDIRECT, INCIDENTAL OR CONSEQUENTIAL
        DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO ECONOMIC LOSS, LOST
        PROFITS OR LOST SAVINGS), WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY,
        EQUITY, CIVIL LAW, STATUTORY LIABILITY OR OTHERWISE, WHICH ARISES OUT OF OR IS
        IN ANY WAY CONNECTED WITH YOUR USE OF THE APP OR ITS CONTENT, THE OPERATION
        OF THIS APP OR ANY FAILURE OR DELAY IN THE OPERATION OF THIS APP EVEN IF
        ADVISED OF THE POSSIBILITY OF DAMAGES, INCLUDING, WITHOUT LIMITATION, YOUR
        CONSUMPTION, PREPARATION, HANDLING OR TRANSPORTATION OF FOOD OR
        BEVERAGES OF ANY KIND IN CONJUNCTION WITH OR IN ANY WAY CONNECTED TO YOUR
        USE OF THE APP. FOR GREATER CERTAINTY, AND WITHOUT RESTRICTING THE
        GENERALITY OF THE FOREGOING, THIS LIMITATION OF LIABILITY OPERATES WITH
        RESPECT TO THE RISKS DEFINED ABOVE.<br/><br/>

        Indemnification<br/><br/>

        YOU WILL INDEMNIFY AND SAVE HARMLESS NÜRI AND ITS RESPECTIVE OFFICERS,
        DIRECTORS, EMPLOYEES, CONSULTANTS, REPRESENTATIVES AND AGENTS FROM AND
        AGAINST ANY CLAIM, CAUSE OF ACTION OR DEMAND, INCLUDING WITHOUT LIMITATION
        REASONABLE LEGAL, ACCOUNTING AND OTHER PROFESSIONAL FEES, BROUGHT AS A
        RESULT OF (1) YOUR USE OF THE APP OR ITS CONTENT; (2) YOUR BREACH OF ANY OF THE
        TERMS CONTAINED IN THESE TERMS OF USE; (3) YOUR VIOLATION OF ANY RIGHTS OF A
        THIRD PARTY OR YOUR CAUSE OF OR CONTRIBUTION TO THE INJURY OR DEATH OF A
        THIRD PARTY. FOR GREATER CERTAINTY, AND WITHOUT RESTRICTING THE GENERALITY
        OF THE FOREGOING, THIS INDEMNITY OPERATES WITH RESPECT TO THE RISKS DEFINED
        ABOVE, INCLUDING CLAIMS IN RESPECT OF SUCH RISKS BROUGHT BY ANY THIRD PARTY.<br/><br/>

        Compliance with Laws<br/><br/>

        You acknowledge and agree that you are solely responsible for compliance with all applicable laws
        and for obtaining all licenses, permits, acknowledgements, consents or other permissions of any kind
        required in connection with, relating to, arising out of, or otherwise in respect of your use of the App
        and you explicitly acknowledge and agree that Nüri has no responsibility and shall have no liability in
        respect thereof. You further acknowledge and agree that you are solely responsible for checking the
        laws relating to production and sale of food in your jurisdiction and complying with all such laws.
        Survival
        The provisions above that follow the heading “Liability Disclaimer” will survive the termination of your
        access to the App.<br/><br/>

        Feedback<br/><br/>

        Nüri welcomes your feedback and comments regarding the App. By submitting or providing us with
        comments, messages, suggestions, ideas, concepts, feedback or other information about the App,
        Nüri and/or its operations, other than Customer Content, which is dealt with above (the
        “Submissions”), you hereby: (i) represent and warrant that none of the Submissions are confidential
        or proprietary to you or to any other party; (ii) represent and warrant that none of the Submissions
        breach any agreement to which you are a party; and (iii) grant Nüri an exclusive fully paid-up, royalty-
        free, perpetual, irrevocable, unrestricted, transferable, sub-licensable, worldwide right and license to
        do with the Submissions anything that you could do, including, without restricting generality of the
        foregoing, use, delete, reproduce, modify, adapt, publish, translate, create derivative works from,
        distribute, display and commercialize the Submissions in any way and for any purpose whatsoever.
        You also waive in favour of Nüri and its successors and assigns any and all of your moral rights in and
        to all Submissions. Furthermore, you agree that Nüri is not responsible for the confidentiality of any
        Submissions.<br/><br/>

        Governing Laws, Jurisdiction<br/><br/>

        You consent and submit to the exclusive jurisdiction of the Courts of Ontario (including the Federal
        Court of Canada to the extent the same has jurisdiction) located in or closest to the City of Hamilton,
        in the Province of Ontario, Canada, in all disputes arising out of or relating to the use of the App and
        these Terms of Use. This agreement and its performance will be governed by the laws of the Province
        of Ontario, Canada, including the federal laws of Canada applicable in that Province.
        The United Nations Convention on Contracts for the International Sale of Goods, also known as the
        “Vienna Convention” shall not apply to these Terms of Use or the agreements herein.<br/><br/>

        Electronic Communications<br/><br/>

        When you visit the App or send e-mails to us, you are communicating with us electronically. You
        consent to receive communications from us electronically. We will communicate with you by e-mail or
        by posting notices on the App. You agree that all agreements, notices, disclosures and other
        communications that we provide to you electronically satisfy any legal requirement that such
        communications be in writing.<br/><br/>

        Severability<br/><br/>

        These Terms of Use will be severable. In the event that any provision is determined to be
        unenforceable or invalid, that provision will nonetheless be enforced to the fullest extent permitted by
        applicable law, and that determination will not affect the validity and enforceability of any other
        remaining provisions of these Terms of Use.<br/><br/>

        Headings
        The headings used in these Terms of Use are included for convenience only and will not limit or
        otherwise affect these Terms of Use.<br/><br/>

        Entire Agreement<br/><br/>

        These Terms of Use, together with those incorporated or referred to in these Terms of Use, constitute
        the entire agreement between us pertaining to the subject matter of these Terms of Use, and
        supersede any prior agreements, understandings, negotiations and discussions, whether electronic,
        oral or written, regarding the subject matter of these Terms of Use, and may not be amended or
        modified except by Nüri as set out above. There are no representations, warranties or other
        agreements between us express or implied, in connection with the subject matter of these Terms of
        Use, except as specifically set out in these Terms of Use or in those incorporated or referred to in
        these Terms of Use. No party has been induced to enter into these Term of Use in reliance on, and
        there will be no liability assessed, either in tort or contract, with respect to, any warranty,
        representation, opinion, advice or assertion of fact, except to the extent it has been reduced to writing
        and included in these Terms of Use or in those incorporated or referred to in these Terms of Use.<br/><br/>

        Enquiries<br/><br/>

        Please direct enquiries to Nüri’s business address at:<br/>
        Nüri Inc.<br/>
        PO Box 30036<br/>
        Ridley Square, St. Catharines, ON<br/>
        L2S 4A1<br/>
        Email: admin@nurieats.com<br/>
        </p>
      </div>
      <footer style={{
  width: '100%',
  backgroundColor: '#81CFF0',
  color: 'white',
  textAlign: 'center',
  padding: '20px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '14px',
  marginTop: 'auto', // Automatically pushes footer to bottom of container
  position: 'relative'
}}>
  <div style={{
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '15px',
    paddingBottom: '10px'
  }}>
    <Link to="/TermsOfUse" style={{ textDecoration: 'none', color: 'white' }}>Terms of Use</Link>
    <Link to="/PrivacyPolicy" style={{ textDecoration: 'none', color: 'white' }}>Privacy Policy</Link>
    <Link to="/Contact" style={{ textDecoration: 'none', color: 'white' }}>Contact</Link>
    <Link to="/Support" style={{ textDecoration: 'none', color: 'white' }}>Support</Link>
  </div>
  <div style={{ color: 'white' }}>
    <p style={{ margin: 0 }}>© {new Date().getFullYear()} NÜRI INC. <a href="https://nurieats.com" style={{ color: 'white', textDecoration: 'none' }}>nurieats.com</a></p>
  </div>
</footer>
    </div>
  );
}

let styles = {

  container: {
    width: '100%', 
    height: 'auto',
    
    // backgroundImage: `url(${backgroundImage})`,
    // border: '6px solid red',
    // backgroundImage: `url(${backgroundImage})`,
  },

};

export default TermsOfUse;
