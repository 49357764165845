import React, { useState, useEffect } from "react";
import logo from "./assets/logo-icons/Icon-8.png";
// import backgroundImage from './assets/UI/Backgrounds/Welcome/Gluten-free@3x.jpg';
import "./App.css";
import "./index.css";
import { Route, Link } from "react-router-dom";
import AD0 from "./assets/images/account_deletion/AD0.png";
import AD1 from "./assets/images/account_deletion/AD1.png";
import AD2 from "./assets/images/account_deletion/AD2.png";
import AD3 from "./assets/images/account_deletion/AD3.png";
import AD4 from "./assets/images/account_deletion/AD4.png";
import AD40 from "./assets/images/account_deletion/AD4-0.png";
import AD5 from "./assets/images/account_deletion/AD5.png";
import AD6 from "./assets/images/account_deletion/AD6.png";
import AD7 from "./assets/images/account_deletion/AD7.png";
import AD8 from "./assets/images/account_deletion/AD8.png";
import AD9 from "./assets/images/account_deletion/AD9.png";
import AD10 from "./assets/images/account_deletion/AD10.png";
import AD11 from "./assets/images/account_deletion/AD11.png";
import AD12 from "./assets/images/account_deletion/AD12.png";
import AD13 from "./assets/images/account_deletion/AD13.png";
import AD14 from "./assets/images/account_deletion/AD14.png";
import AD15 from "./assets/images/account_deletion/AD15.png";
import AD16 from "./assets/images/account_deletion/AD16.png";
import AD17 from "./assets/images/account_deletion/AD17.png";
import AD18 from "./assets/images/account_deletion/AD18.png";
import AD19 from "./assets/images/account_deletion/AD19.png";
import AD20 from "./assets/images/account_deletion/AD20.png";
import AD21 from "./assets/images/account_deletion/AD21.png";
import AD22 from "./assets/images/account_deletion/AD22.png";
import AD23 from "./assets/images/account_deletion/AD23.png";
import AD24 from "./assets/images/account_deletion/AD24.png";
import AD25 from "./assets/images/account_deletion/AD25.png";
import AD26 from "./assets/images/account_deletion/AD26.png";
import AD27 from "./assets/images/account_deletion/AD27.png";
import AD28 from "./assets/images/account_deletion/AD28.png";
import { Helmet } from "react-helmet";

function AccountDeletion() {
  useEffect(function () {});

  const [button0Style, setButton0Style] = useState({
    backgroundColor: "#C981F0",
    border: "0px",
    color: "white",
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [button1Style, setButton1Style] = useState({
    backgroundColor: "#C981F0",
    border: "0px",
    color: "white",
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [buttonStyleNormal] = useState({
    backgroundColor: "#C981F0",
    border: "0px",
    color: "white",
    height: 33,
    width: 200,
    borderWidth: 1,
    fontSize: 20,
  });

  const [buttonStyleHover] = useState({
    backgroundColor: "white",
    border: "0px",
    // borderTopWidth: 0,
    // borderLeftWidth: 0,
    // borderBottomWidth: 0,
    color: "#81CFF0",
    height: 33,
    width: 200,
    borderWidth: 1,
    fontSize: 20,
  });

  function mouseHover(buttonIndex) {
    if (buttonIndex === 0) {
      setButton0Style(buttonStyleHover);
    } else if (buttonIndex === 1) {
      setButton1Style(buttonStyleHover);
    }
  }

  function mouseOut(buttonIndex) {
    if (buttonIndex === 0) {
      setButton0Style(buttonStyleNormal);
    } else if (buttonIndex === 1) {
      setButton1Style(buttonStyleNormal);
    }
  }

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
  };

  return (
    <div id={"top"} style={styles.container}>
      <Helmet>
        <html lang="en" />
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="Nüri Inc." />
        <meta
          name="description"
          content="Nüri is the official marketplace for home cooked foods!"
        />
        <title>Nüri | Account Deletion</title>
      </Helmet>
      <div
        style={{
          // border: '3px solid red',
          width: "100%",
          height: "100vh",
          maxHeight: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          pointerEvents: "none",
        }}
      >
        <a
          href="#top"
          // onClick={handleClick}
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            padding: 20,
            borderRadius: 20,
            width: 160,
            margin: 20,
            pointerEvents: "auto",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#C981F0",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Drop shadow effect
            textDecoration: "none",
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
            userSelect: "none",
            display: "flex",
            marginBottom: 100,
            // zIndex: 1000
          }}
        >
          Return to Quick Links
        </a>
      </div>

      <title>Nüri | Account Deletion</title>

      <div
        style={{
          marginTop: 50,
          paddingBottom: 0,
          height: "auto",
          width: "100%",
          border: "0px solid red",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h5
          style={{
            width: "50%",
            color: "darkBlue",
            fontSize: 30,
            textAlign: "center",
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          {`Deleting or deactivating your Nüri account`}
        </h5>

        <h3 style={{ marginBottom: 0 }}>Foodie quick links</h3>
        <a
          style={{ textAlign: "center" }}
          href={"#foodieDeactivateDelete"}
          onClick={() => null}
        >
          Navigating to the Foodie deactivate/delete account screen.
        </a>
        <a
          style={{ textAlign: "center" }}
          href={"#foodieDeactivate"}
          onClick={() => null}
        >
          Deactivate Foodie account.
        </a>
        <a
          style={{ textAlign: "center" }}
          href={"#foodieReactivateDeactivate"}
          onClick={() => null}
        >
          Reactivate Foodie account after deactivation.
        </a>
        <a
          style={{ textAlign: "center" }}
          href={"#foodieDelete"}
          onClick={() => null}
        >
          Delete Foodie account.
        </a>
        <a
          style={{ textAlign: "center" }}
          href={"#foodieReactivateDelete"}
          onClick={() => null}
        >
          Reactivate Foodie account after deletion.
        </a>

        <h3 style={{ marginBottom: 0 }}>Cook quick links</h3>
        <a
          style={{ textAlign: "center" }}
          href={"#cookDeactivateDelete"}
          onClick={() => null}
        >
          Navigating to the Cook deactivate/delete account screen.
        </a>
        <a
          style={{ textAlign: "center" }}
          href={"#cookDeactivate"}
          onClick={() => null}
        >
          Deactivate Cook account.
        </a>
        <a
          style={{ textAlign: "center" }}
          href={"#cookReactivateDeactivate"}
          onClick={() => null}
        >
          Reactivate Cook account after deactivation.
        </a>
        <a
          style={{ textAlign: "center" }}
          href={"#cookDelete"}
          onClick={() => null}
        >
          Delete Cook account.
        </a>
        <a
          style={{ textAlign: "center" }}
          href={"#cookReactivateDelete"}
          onClick={() => null}
        >
          Reactivate Cook account after deletion.
        </a>

        <p style={{ textAlign: "left", width: "80%" }}>
          Dear Valued Nüri Community Member,
          <br />
          <br />
          We understand that privacy is of utmost importance to our users. This
          Account Deletion Policy outlines the procedures and assurances
          regarding the deletion of user accounts on the Nüri platform.
          <br />
          <br />
          <br />
          <b>For Foodies:</b>
          <br />
          <br />
          Upon initiating the account deletion process, rest assured that Nüri
          is committed to expeditiously and irreversibly erasing all personal
          information associated with your account. This includes, but is not
          limited to, your name, email address, physical address, and phone
          number. Nüri shall uphold the highest standards of data protection and
          comply with all applicable data privacy laws.
          <br />
          <br />
          It is imperative to note that transactional data, a vital component
          for Cooks to track sales history, will be retained for analytical
          purposes. However, this data will be diligently disassociated from any
          personally identifiable information, maintaining a strict separation
          between transactional histories and personal details.
          <br />
          <br />
          Foodies who wish to deactivate or delete their accounts will
          subsequently no longer have access to their Cook accounts (if
          applicable). They may re-register for a new Cook account using a
          different e-mail address.
          <br />
          <br />
          <br />
          <b>For Cooks:</b>
          <br />
          <br />
          Similar to Foodies, Cooks seeking account deletion can trust in Nüri's
          commitment to safeguarding their privacy. All personal information,
          including, but not limited to, names and contact details, will be
          expunged from our records in accordance with regulatory guidelines.
          <br />
          <br />
          To accommodate the unique requirements of Cooks, menu item data shall
          be retained to ensure continuity in Foodie order history. However,
          with the utmost respect for privacy, the Cook's name associated with
          any historical orders will be meticulously disassociated, preserving
          the anonymity of Cooks who have departed from the platform.
          <br />
          <br />
          By leveraging advanced data disassociation techniques, Nüri ensures
          that any retained information is devoid of personally identifiable
          attributes, upholding the principles of privacy and confidentiality.
          <br />
          <br />
          Cooks who wish to deactivate or delete their accounts will
          subsequently no longer have access to their Foodie accounts (if
          applicable). They may re-register for a new Foodie account using a
          different e-mail address.
          <br />
          <br />
          For further inquiries or to initiate the account deletion process,
          please contact our dedicated support team at <br />
          1-(877)-638-0995 (Toll-free).
          <br />
          <br />
          Thank you for being a part of the Nüri community.
        </p>

        <h5
          style={{
            width: "50%",
            color: "red",
            fontSize: 20,
            textAlign: "center",
            marginTop: 30,
            marginBottom: 0,
          }}
        >
          {`How to delete or deactivate your Nüri account`}
        </h5>

        <p style={{ textAlign: "left", width: "80%", fontWeight: "bold" }}>
          Account deletion/deactivation is only possible via the Nüri mobile app
          for iOS or Android.
          <br />
          <br />
          Please follow the instruction below to delete or deactivate your Nüri
          account:
          <br />
          <br />
          <br />
          <b
            id={"foodieDeactivateDelete"}
            style={{ color: "darkBlue", fontSize: 20 }}
          >
            Foodies: Navigating to the Account Deactivation/Deletion screen.
          </b>
          <br />
          <br />
          <b>1. Log into your Foodie account:</b>
          <br />
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD0}
          />
          <br />
          <br />
          <b>
            2. Tap the Ü icon in the middle of the bottom tab navigator to
            access your profile, then, tap the account settings button (cog
            wheel) located at the very top right of the screen as shown below:
          </b>
          <br />
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD1}
          />
          <br />
          <br />
          <b>
            3. In the settings menu, you will see a button labelled
            "Deactivate/Delete account". Go ahead and tap on it.
          </b>
          <br />
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD2}
          />
          <br />
          <br />
          <b>
            4. Please review the information before proceeding to the next step.
          </b>
          <br />
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD3}
          />
          <br />
          <br />
          <b
            id={"foodieDeactivate"}
            style={{ color: "darkBlue", fontSize: 20 }}
          >
            Foodie account deactivation.
          </b>
          <br />
          <p>
            1. To initiate an account deactivation, please ensure that you
            select the upper-most button labelled in blue text that says
            "Deactivate my Nüri Account".
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD4}
          />
          <p>
            2. You will be prompted one final time to make the decision by a
            dialog box.
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD5}
          />
          <p>
            3. After you confirm your account deactivation, you will be taken
            back to the welcome screen and your account deactivation will be
            confirmed by a dialog window.
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD6}
          />
          <br />
          <br />
          <b
            id={"foodieReactivateDeactivate"}
            style={{ color: "darkBlue", fontSize: 20 }}
          >
            Foodie account reactivation after deactivation.
          </b>
          <br />
          <p>
            If you want to reactivate your account after deactivating it, you
            can come back any time to do so. Just follow the steps below and
            you'll be back on Nüri in no time!
          </p>
          <p>1. Log into your Nüri account.</p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD0}
          />
          <p>
            2. You will be asked if you would like to reactivate your account.
            Tap the green labelled button that says "Reactivate my Nüri Account"
            to proceed. Otherwise, you can tap the back button if you change
            your mind.
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD7}
          />
          <p>3. Confirm reactivation.</p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD8}
          />
          <br />
          <br />
          <b id={"foodieDelete"} style={{ color: "darkBlue", fontSize: 20 }}>
            Foodie account deletion.
          </b>
          <br />
          <p>
            To fully delete your account and any personal data associated with
            your account, follow the steps below. Please note: there is a
            processing period of up to 90 days to complete the account deletion.{" "}
          </p>
          <p>1. Log into your Nüri account.</p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD0}
          />
          <p>
            2. Tap the Ü icon in the middle of the bottom tab navigator to
            access your profile, then, tap the account settings button (cog
            wheel) located at the very top right of the screen as shown below.
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD1}
          />
          <p>3. In the settings menu, tap on "Deactivate/delete account".</p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD2}
          />
          <p>
            4. To initiate the account deletion process, including the full
            deletion of all your personal data, please ensure to tap on the
            bottom-most button labelled in orange that says "Request to
            permanently delete my Nüri account and my user data".
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD40}
          />
          <p>
            5. A dialog box will appear that will ask you for a final
            confirmation. To move forward with your decision, tap on "Deactivate
            and Delete Account".
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD9}
          />
          <p>
            6. You will be taken back to the welcome screen with a final
            confirmation that your request for account deletion was successful.
            You will be provided with case reference number for your records in
            case you ever need to contact us regarding your account in the
            future. This case number will expire after the account has been
            fully deleted.
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD10}
          />
          <br />
          <br />
          <b
            id={"foodieReactivateDelete"}
            style={{ color: "darkBlue", fontSize: 20 }}
          >
            Foodie account reactivation after deletion (if initiated before 90
            day processing period).
          </b>
          <br />
          <p>
            If you previously requested for a complete account deletion, you
            still have up to 90 days to restore your account. If your account
            deletion has not yet been initiated by our admins, there is still
            time to restore your account and all your data. Follow the steps
            below to complete account restoration.
          </p>
          <p>1. Log into your Nüri account.</p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD0}
          />
          <p>
            2. You will be asked if you would like to reactivate your account.
            Tap the green labelled button that says "Reactivate my Nüri Account"
            to proceed. Otherwise, you can tap the back button if you change
            your mind.
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD11}
          />
          <p>
            3. Confirm reactivation. You will be notified that the 90-day
            processing period will be reset if you decide to request another
            account deletion in the future.
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD12}
          />
          {/* COOK STUFF STARTS HERE */}
          <br />
          <br />
          <br />
          <b
            id={"cookDeactivateDelete"}
            style={{ color: "darkBlue", fontSize: 20 }}
          >
            Cook: Navigating to the Account Deactivation/Deletion screen
          </b>
          <p>1. Log into your Nüri account</p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD0}
          />
          <p>
            2. Tap the Ü icon in the middle of the bottom tab navigator to
            access your profile, then, tap the account settings button (cog
            wheel) located at the very top left of the screen as shown below.
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD13}
          />
          <p>3. In the settings menu, tap on "Deactivate/delete account".</p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD14}
          />
          <p>
            4. Please review the information before proceeding to the next step.
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD15}
          />
          <br />
          <br />
          <b id={"cookDeactivate"} style={{ color: "darkBlue", fontSize: 20 }}>
            Deactivate Cook account
          </b>
          <br />
          <p>
            1. Tap on the upper-most button labelled in blue text that says
            "Deactivate my Nüri account".
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD16}
          />
          {/* <img style={{width: 300, height: 'auto', margin: 5, marginLeft: 0}} src={AD17} /> */}
          <p>2. You will be prompted one final time to make your decision.</p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD18}
          />
          <p>
            3. After a successfull account deletion, you will be taken back to
            the welcome screen and be presented with a confirmation dialog
            window.
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD19}
          />
          <br />
          <br />
          <b
            id={"cookReactivateDeactivate"}
            style={{ color: "darkBlue", fontSize: 20 }}
          >
            Cook account reactivation after deactivation.
          </b>
          <br />
          <p>
            If you want to reactivate your account after deactivating it, you
            can come back any time to do so. Just follow the steps below and
            you'll be back on Nüri in no time!
          </p>
          <p>1. Log into your Nüri account.</p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD0}
          />
          <p>
            2. You will be asked if you would like to reactivate your account.
            Tap the green labelled button that says "Reactivate my Nüri Account"
            to proceed. Otherwise, you can tap the back button if you change
            your mind.
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD20}
          />
          <p>3. Confirm reactivation.</p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD21}
          />
          <br />
          <br />
          <b id={"cookDelete"} style={{ color: "darkBlue", fontSize: 20 }}>
            Cook account deletion.
          </b>
          <br />
          <p>
            1. Tap on the bottom-most button labelled in orange text that says
            "Request to permanently delete my Nüri account and my user data".
          </p>
          {/* <img style={{width: 300, height: 'auto', margin: 5, marginLeft: 0}} src={AD0} /> */}
          {/* <img style={{width: 300, height: 'auto', margin: 5, marginLeft: 0}} src={AD13} /> */}
          {/* <img style={{width: 300, height: 'auto', margin: 5, marginLeft: 0}} src={AD14} /> */}
          {/* <img style={{width: 300, height: 'auto', margin: 5, marginLeft: 0}} src={AD15} /> */}
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD23}
          />
          {/* <img style={{width: 300, height: 'auto', margin: 5, marginLeft: 0}} src={AD17} /> */}
          <p>2. You will be prompted one final time to confirm your choice.</p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD24}
          />
          <p>
            3. After successful account deletion, you will be taken back to the
            welcome screen. Please take note of the case reference number for
            your records.
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD25}
          />
          <br />
          <br />
          <b
            id={"cookReactivateDelete"}
            style={{ color: "darkBlue", fontSize: 20 }}
          >
            Cook account reactivation after deletion (if initiated before 90 day
            processing period).
          </b>
          <br />
          <p>1. Log into your Nüri account.</p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD0}
          />
          <p>
            2. You will be asked if you would like to reactivate your account.
            Tap the button with green text to proceed.
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD26}
          />
          <p>
            3. Confirm reactivation. You will be notified that the 90-day
            processing period will be reset if you decide to request another
            account deletion in the future.
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD27}
          />
          <p>
            4. After successful reactivation, a confirmation window will appear
            letting you know that your account has been restored successfully.
          </p>
          <img
            style={{ width: 300, height: "auto", margin: 5, marginLeft: 0 }}
            src={AD28}
          />
        </p>
      </div>

      <footer
        style={{
          width: "100%",
          backgroundColor: "#81CFF0",
          color: "white",
          textAlign: "center",
          padding: "20px 0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "14px",
          marginTop: "auto", // Automatically pushes footer to bottom of container
          position: "relative",
          // marginTop: 50
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "15px",
            paddingBottom: "10px",
          }}
        >
          <Link
            to="/TermsOfUse"
            style={{ textDecoration: "none", color: "white" }}
          >
            Terms of Use
          </Link>
          <Link
            to="/PrivacyPolicy"
            style={{ textDecoration: "none", color: "white" }}
          >
            Privacy Policy
          </Link>
          <Link
            to="/Contact"
            style={{ textDecoration: "none", color: "white" }}
          >
            Contact
          </Link>
          <Link
            to="/Support"
            style={{ textDecoration: "none", color: "white" }}
          >
            Support
          </Link>
        </div>
        <div style={{ color: "white" }}>
          <p style={{ margin: 0 }}>
            © {new Date().getFullYear()} NÜRI INC.{" "}
            <a
              href="https://nurieats.com"
              style={{ color: "white", textDecoration: "none" }}
            >
              nurieats.com
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
}

let styles = {
  container: {
    width: "100%",
    height: "auto",
    // backgroundImage: `url(${backgroundImage})`,
    // border: '6px solid red',
    // backgroundImage: `url(${backgroundImage})`,
  },
};

export default AccountDeletion;
