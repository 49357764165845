import React, { useState, useEffect, Suspense, lazy } from "react";
import logo from "./assets/logo-icons/Icon-8.png";
import "./App.css";
import "./index.css";
import "./assets/fonts/fonts.css";
import { Route, Routes, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import NavBar from "./components/NavBar.js";
import NavBarLegacy from "./components/NavBarLegacy.js";
import Home from "./Home";
import Watch from "./Watch";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";
import Contact from "./Contact";
import Support from "./Support";
import AccountDeletion from "./AccountDeletion";
import BrandingGuidelines from "./BrandingGuidelines";

function App() {
  //App
  var [number, setNumber] = useState(window.outerWidth);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const currentYear = new Date().getFullYear();
  const [currentScreen, setCurrentScreen] = useState();

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    console.log(innerWidth);
    return { innerWidth, innerHeight };
  }

  function handleSetCurrentScreen(screen) {
    setCurrentScreen(screen);
  }

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="Nüri Inc." />
        <meta
          name="description"
          content="Nüri is the official marketplace for home cooked foods!"
        />
        <title>Nüri</title>
      </Helmet>
      {(windowSize.innerWidth >= 900 && (
        <div style={styles.container}>
          <div style={styles.header}>
            <div style={styles.logo}>
              <h1 style={{ fontSize: 50 }} aria-label="Nüri Logo">
                <span style={{ color: "white", letterSpacing: -2 }}>n</span>
                <span
                  className={"bold"}
                  style={{ color: "#C981F0", letterSpacing: -1 }}
                >
                  ü
                </span>
                <span style={{ color: "white", letterSpacing: 0 }}>r</span>
                <span style={{ color: "white" }}>i</span>
              </h1>
            </div>
            {/* <div style={styles.logo}>
            <h1
              style={{
                letterSpacing: -1,
                float: "left",
                lineHeight: 0,
                fontSize: 50,
                fontWeight: "normal",
                color: "white",
              }}
            >
              n
            </h1>
            <h1
              className={"bold"}
              style={{
                letterSpacing: -1,
                float: "left",
                lineHeight: 0,
                fontSize: 50,
                // fontWeight: 'bold',
                color: "#C981F0",
              }}
            >
              ü
            </h1>
            <h1
              style={{
                letterSpacing: -1,
                float: "left",
                lineHeight: 0,
                fontSize: 50,
                fontWeight: "normal",
                color: "white",
              }}
            >
              r
            </h1>
            <h1
              style={{
                letterSpacing: -1,
                float: "left",
                marginBottom: 0,
                fontSize: 50,
                lineHeight: 0,
                fontWeight: "normal",
                color: "white",
              }}
            >
              i
            </h1>
          </div> */}
            <div style={styles.slogan}>
              <h2
                style={{
                  fontSize: 20,
                  marginTop: 0,
                  lineHeight: 0,
                  color: "white",
                  fontWeight: "normal",
                }}
                aria-label="Feed your passion" // Added aria-label for accessibility
                title="Feed your passion" // Optional: Added title attribute for better SEO visibility
              >
                Feed your passion.
              </h2>
            </div>
            {/* <div style={styles.slogan}>
            <h2
              style={{
                fontSize: 20,
                marginTop: 0,
                lineHeight: 0,
                color: "white",
                fontWeight: "normal",
              }}
            >
              Feed your passion.
            </h2>
          </div> */}
          </div>

          {/* Inside your JSX where the navbar is used */}
          <nav aria-label="Main navigation">
            <NavBarLegacy currentScreen={currentScreen} windowSize={windowSize} />
          </nav>

          <Routes>
            <Route
              path="/"
              exact
              element={
                <Home
                  setCurrentScreen={(screen) => handleSetCurrentScreen(screen)}
                  windowSize={windowSize}
                />
              }
            />
            <Route
              path="/Watch"
              exact
              element={
                <Watch
                  setCurrentScreen={(screen) => handleSetCurrentScreen(screen)}
                  windowSize={windowSize}
                />
              }
            />
            <Route
              path="/PrivacyPolicy"
              exact
              element={
                <PrivacyPolicy
                  setCurrentScreen={(screen) => handleSetCurrentScreen(screen)}
                  windowSize={windowSize}
                />
              }
            />
            <Route
              path="/Contact"
              exact
              element={
                <Contact
                  setCurrentScreen={(screen) => handleSetCurrentScreen(screen)}
                  windowSize={windowSize}
                />
              }
            />
            <Route
              path="/Support"
              exact
              element={
                <Support
                  setCurrentScreen={(screen) => handleSetCurrentScreen(screen)}
                  windowSize={windowSize}
                />
              }
            />
            <Route
              path="/TermsOfUse"
              exact
              element={<TermsOfUse windowSize={windowSize} />}
            />
            <Route
              path="/AccountDeletion"
              exact
              element={<AccountDeletion windowSize={windowSize} />}
            />
            <Route
              path="/BrandingGuidelines"
              exact
              element={<BrandingGuidelines windowSize={windowSize} />}
            />
          </Routes>
        </div>
      )) || (
        <div style={styles.container}>
          <div style={styles.header}>
            <div style={styles.logoMobile}>
              <h1 style={{ fontSize: 50 }} aria-label="Nüri Logo">
                <span style={{ color: "white", letterSpacing: -2 }}>n</span>
                <span
                  className={"bold"}
                  style={{ color: "#C981F0", letterSpacing: -1 }}
                >
                  ü
                </span>
                <span style={{ color: "white", letterSpacing: 0 }}>r</span>
                <span style={{ color: "white" }}>i</span>
              </h1>
            </div>
            {/* <div style={styles.logo}>
            <h1
              style={{
                letterSpacing: -1,
                float: "left",
                lineHeight: 0,
                fontSize: 50,
                fontWeight: "normal",
                color: "white",
              }}
            >
              n
            </h1>
            <h1
              className={"bold"}
              style={{
                letterSpacing: -1,
                float: "left",
                lineHeight: 0,
                fontSize: 50,
                color: "#C981F0",
              }}
            >
              ü
            </h1>

            <h1
              style={{
                letterSpacing: -1,
                float: "left",
                lineHeight: 0,
                fontSize: 50,
                fontWeight: "normal",
                color: "white",
              }}
            >
              r
            </h1>
            <h1
              style={{
                letterSpacing: -1,
                float: "left",
                marginBottom: 0,
                fontSize: 50,
                lineHeight: 0,
                fontWeight: "normal",
                color: "white",
              }}
            >
              i
            </h1>
          </div> */}
            <div style={styles.slogan}>
              <h2
                style={{
                  fontSize: 20,
                  marginTop: 0,
                  lineHeight: 0,
                  color: "white",
                  fontWeight: "normal",
                }}
                aria-label="Feed your passion" // Added aria-label for accessibility
                title="Feed your passion" // Optional: Added title attribute for better SEO visibility
              >
                Feed your passion.
              </h2>
            </div>
            {/* <div style={styles.slogan}>
            <h2
              style={{
                fontSize: 20,
                marginTop: 0,
                lineHeight: 0,
                color: "white",
                fontWeight: "normal",
              }}
            >
              Feed your passion.
            </h2>
          </div> */}
          </div>

          <nav aria-label="Main navigation">
            <NavBar currentScreen={currentScreen} windowSize={windowSize} />
          </nav>

          <Routes>
            <Route
              path="/"
              exact
              element={
                <Home
                  setCurrentScreen={(screen) => handleSetCurrentScreen(screen)}
                  windowSize={windowSize}
                />
              }
            />
            <Route
              path="/Watch"
              exact
              element={
                <Watch
                  setCurrentScreen={(screen) => handleSetCurrentScreen(screen)}
                  windowSize={windowSize}
                />
              }
            />
            <Route
              path="/PrivacyPolicy"
              exact
              element={
                <PrivacyPolicy
                  setCurrentScreen={(screen) => handleSetCurrentScreen(screen)}
                  windowSize={windowSize}
                />
              }
            />
            <Route
              path="/Contact"
              exact
              element={
                <Contact
                  setCurrentScreen={(screen) => handleSetCurrentScreen(screen)}
                  windowSize={windowSize}
                />
              }
            />
            <Route
              path="/Support"
              exact
              element={
                <Support
                  setCurrentScreen={(screen) => handleSetCurrentScreen(screen)}
                  windowSize={windowSize}
                />
              }
            />
            <Route
              path="/TermsOfUse"
              exact
              element={<TermsOfUse windowSize={windowSize} />}
            />
            <Route
              path="/AccountDeletion"
              exact
              element={<AccountDeletion windowSize={windowSize} />}
            />
            <Route
              path="/BrandingGuidelines"
              exact
              element={<BrandingGuidelines windowSize={windowSize} />}
            />
          </Routes>
        </div>
      )}
    </div>
  );
}

let styles = {
  header: {
    width: "100%",
    height: "auto",
    paddingBottom: 15,
    paddingTop: 15,
    backgroundColor: "#81CFF0",
  },
  footer: {
    bottom: 0,
    backgroundColor: "#81CFF0",
    width: "100%",
    marginTop: "auto",
  },
  navBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#C981F0",
  },

  button: {
    backgroundColor: "#C981F0",
    border: "1px solid white",
    color: "white",
    height: 35,
    width: 200,
    borderWidth: 1,
    fontSize: 10,
  },
  container: {
    width: "100%",
  },

  body: {
    width: "100%",
    height: "auto",
  },
  logo: {
    paddingLeft: 35,
    height: 80,
    marginBottom: -13,
  },
  logoMobile: {
    paddingLeft: 35,
    height: 80,
    marginBottom: -13,
  },
  slogan: {
    paddingLeft: 35,
    height: 30,
  },
};

export default App;
