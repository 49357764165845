import React, { useState, useEffect } from "react";
import "../App.css";
import AnimatedTextButton from "./AnimatedTextButton";
import { FaBars } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";


function NavBar({ handleToggleTheme, theme, handleMoveToComponent }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900); // Adjust the threshold as needed

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleResize = () => {
    const isCurrentlyMobile = window.innerWidth <= 900;
    setIsMobile(isCurrentlyMobile);

    // Close drawer if currently open and resized to desktop
    if (drawerOpen && !isCurrentlyMobile) {
      setDrawerOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [drawerOpen]);

  return (
    // className="navbar-container"
    <div >
      {/* Drawer (slides from top and only takes required height) */}
      <div className={`drawer ${drawerOpen ? "open" : ""}`}>
        <div className="drawer-header">
          {/* Close Button */}
          <button style={{marginLeft: 30, marginTop: 15}} className="close-button" onClick={toggleDrawer}>
            <FaArrowLeft style={{color: 'white'}} size={30} />
          </button>
        </div>
        <div className="drawer-content">
          <AnimatedTextButton
              text={"Home"}
              onClick={() => setDrawerOpen(false)}
              theme={theme}
              isNavItem={true}
              navRouteName={''}
            />
            <AnimatedTextButton
              text={"Watch"}
              onClick={() => setDrawerOpen(false)}
              theme={theme}
              isNavItem={true}
              navRouteName={'Watch'}
            />
            <AnimatedTextButton
              text={"Privacy Policy"}
              onClick={() => setDrawerOpen(false)}
              theme={theme}
              isNavItem={true}
              navRouteName={'PrivacyPolicy'}
            />
            
            <AnimatedTextButton
              text={"Contact"}
              onClick={() => setDrawerOpen(false)}
              theme={theme}
              isNavItem={true}
              navRouteName={'Contact'}
            />

            <AnimatedTextButton
              text={"Support"}
              onClick={() => setDrawerOpen(false)}
              theme={theme}
              isNavItem={true}
              navRouteName={'Support'}
            />
        </div>
      </div>

      {/* Navbar content */}
      
      {/* className="navbar-content frosted-glass-container-blur-plus" */}
        {/* Conditionally render menu items based on isMobile state */}
        {!isMobile && (
          <>
          <div style={{border: '0px solid red', flexDirection: 'row', width: '60%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <AnimatedTextButton
              text={"Home"}
              onClick={() => handleMoveToComponent(0)}
              theme={theme}
            />
            <AnimatedTextButton
              text={"Watch"}
              onClick={() => handleMoveToComponent(1)}
              theme={theme}
            />
            <AnimatedTextButton
              text={"Privacy Policy"}
              onClick={() => handleMoveToComponent(2)}
              theme={theme}
            />
            
            <AnimatedTextButton
              text={"Contact"}
              onClick={() => handleMoveToComponent(3)}
              theme={theme}
            />

            <AnimatedTextButton
              text={"Support"}
              onClick={() => handleMoveToComponent(4)}
              theme={theme}
            />
            </div>
          </>
        )}

        {/* Hamburger icon visible only on mobile */}
        {isMobile && (
          <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#C981F0', padding: 0, border: '0px solid black'}}>
            <button style={{border: '0px solid red', background: 'none'}} onClick={toggleDrawer}>
              <FaBars size={33} style={{height: 'auto', color: 'white', padding: 5, marginLeft: 0, marginTop: 3}} />
            </button>
            {/* <button style={{ marginLeft: 10, color: 'white', background: 'none', border: 'none', fontSize: 30 }} onClick={toggleDrawer}>
              {<FaBars style={{border: '1px solid black',}} />}
            </button> */}
          </div>
          
        )}
        {/* {isMobile && (
          <div style={{width: '100%', marginRight: 'auto', display: 'flex', flexDirection: 'row'}} >
            <div style={{width: '50%', border: '0px solid white'}}>
              <button style={{ color: 'white', fontSize: 30 }}  onClick={toggleDrawer}>
                {<FaBars />}
              </button>
            </div>
            <div style={{width: '50%', border: '0px solid white', display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
              <h3 style={{ marginLeft: 'auto'}}>
                JORDAN&nbsp;
              </h3>
              <h3 className="bolden-gate" style={{ color: '#6e6ef4' }}>
                JANCIC
              </h3>
            </div>
          
          </div>
        )} */}
      
    </div>
  );
}

export default NavBar;