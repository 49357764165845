import React, { useState, useEffect } from "react";
import logo from "./assets/logo-icons/Icon-8.png";
// import backgroundImage from './assets/UI/Backgrounds/Welcome/Gluten-free@3x.jpg';
import "./App.css";
import "./index.css";
import { Route, Link } from "react-router-dom";

function Watch(props) {
  
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [videoList, setVideoList] = useState([
    {
      title: "Founder - Nüri Inc.",
      description:
        "Meet the founder of Nüri, the app bridging Foodies with talented Home Cooks. Hear the story behind Nüri, the inspiration to bring fresh, local meals to everyone, and the vision to empower cooks to share their passion. Join us as we transform the way communities connect over food!",
      url: "https://www.youtube.com/embed/-q0t5OkkidQ?si=wsYvEtfMCpfXIrPR",
      thumbnail: "https://img.youtube.com/vi/-q0t5OkkidQ/hqdefault.jpg",
      length: "0:45",
    },
    {
      title: "Cooks Sales Pitch",
      description:
        "Join Nüri and turn your passion for cooking into income! With Nüri, you get the freedom to share your homemade dishes with local Foodies while setting your own schedule and prices. Reach a wider audience, build your own brand, and make money doing what you love—all with zero upfront costs. Become a part of Nüri today and start serving delicious, authentic meals to people in your community!",
      url: "https://www.youtube.com/embed/IYiCfE-PAos?si=7gnZLoGe7pK0hKDU",
      thumbnail: "https://img.youtube.com/vi/IYiCfE-PAos/hqdefault.jpg",
      length: "0:40",
    },
    {
      title: "Foodies",
      description:
        "Foodies on Nüri are adventurous eaters who crave authentic, homemade meals from talented local cooks. As a Foodie, you get to explore unique flavors, support independent cooks, and enjoy fresh, personalized dishes you won’t find in restaurants. Whether you're ordering a favorite comfort food or trying something new, Nüri lets you connect with passionate cooks and savor the rich diversity of your community, one meal at a time!",
      url: "https://www.youtube.com/embed/CM_FtrS-9gs?si=APeU4r-7dbDlNmmR",
      thumbnail: "https://img.youtube.com/vi/CM_FtrS-9gs/hqdefault.jpg",
      length: "0:46",
    },
    {
      title: "Cooks",
      description: 
        "Cooks on Nüri are skilled home chefs who turn their love for cooking into a way to share delicious meals with their community. As a Cook, you have the freedom to showcase your unique recipes, set your own hours, and create a personal menu that reflects your culinary style. Nüri makes it easy for you to connect with Foodies who value homemade quality, helping you grow your business while doing what you love—cooking from the heart.",
      url: "https://www.youtube.com/embed/N3lsgkU3UOI?si=OlJc3m--h0RaAlU6",
      thumbnail: "https://img.youtube.com/vi/N3lsgkU3UOI/hqdefault.jpg",
      length: "0:45",
    },
  ]);
  const [selectedVideo, setSelectedVideo] = useState(videoList[0]);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);

  useEffect(() => {
    props.setCurrentScreen('watch');
  },[]);

  function selectVideo(videoIndex) {
    setSelectedVideo(videoList[videoIndex]);
    setSelectedVideoIndex(videoIndex);

    // Scroll to the element with the ID 'watch'
    const element = document.getElementById("watch");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Smooth scroll to the element
    }
  };

  

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [button0Style, setButton0Style] = useState({
    backgroundColor: "#C981F0",
    border: "0px",
    color: "white",
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [button1Style, setButton1Style] = useState({
    backgroundColor: "#C981F0",
    border: "0px",
    color: "white",
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [buttonStyleNormal] = useState({
    backgroundColor: "#C981F0",
    border: "0px",
    color: "white",
    height: 33,
    width: 200,
    borderWidth: 1,
    fontSize: 20,
  });

  const [buttonStyleHover] = useState({
    backgroundColor: "white",
    border: "0px",
    color: "#81CFF0",
    height: 33,
    width: 200,
    borderWidth: 1,
    fontSize: 20,
  });

  function mouseHover(buttonIndex) {
    if (buttonIndex === 0) {
      setButton0Style(buttonStyleHover);
    } else if (buttonIndex === 1) {
      setButton1Style(buttonStyleHover);
    }
  }

  function mouseOut(buttonIndex) {
    if (buttonIndex === 0) {
      setButton0Style(buttonStyleNormal);
    } else if (buttonIndex === 1) {
      setButton1Style(buttonStyleNormal);
    }
  }

  return (
    (windowSize.innerWidth > 900 && (
      <div>
        <div style={styles.container}>
          <title>Nüri | Watch</title>
          <div
            style={{
              width: "80%",
              border: "0px solid orange",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start", // Align items to the top,
              padding: 20,
              // borderBottom: ".5px solid #d6d6d6",
            }}
          >
            <div
              style={{
                width: "50%",
                // border: "3px solid orange",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    paddingBottom: "56.25%", // Maintains 16:9 aspect ratio
                    height: 0,
                    borderRadius: 30,
                    overflow: "hidden",
                    border: "3px solid white",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)", // Adds the drop shadow
                  }}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: 30,
                    }}
                    src={`${selectedVideo.url}`} // Added autoplay parameter
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>

                <div style={{ border: "0px solid red" }}>
                  <h1
                    className={"bold"}
                    style={{
                      color: "#82CFF0",
                      fontSize: 30,
                      lineHeight: 1,
                      textAlign: "left",
                      marginTop: 20,
                      marginBottom: 0,
                      borderBottom: "5px solid #C981F0",
                      paddingBottom: 5,
                    }}
                  >
                    {`${selectedVideo.title}`}
                  </h1>
                  <p
                    style={{
                      textAlign: "left",
                      fontSize: 16,
                      marginTop: 7,
                    }}
                  >
                    {selectedVideo.description}
                  </p>
                </div>
              </div>
            </div>
            <div style={{ width: "2%", border: "0px solid green" }} />
            <div
              style={{
                width: "49%",
                height: "100%",
                border: "0px solid green",
                // paddingTop: 10,
              }}
            >
              {videoList.map((video, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: 10,
                    paddingTop: 10,
                    border: "0px solid black",
                    cursor: "pointer", // Changes cursor to hand on hover
                    backgroundColor:
                      selectedVideoIndex === index ? "#f7f7f7" : "white",
                    borderRadius: 20,
                    boxShadow:
                      selectedVideoIndex === index
                        ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                        : null,
                    // zIndex: selectedVideoIndex === index ? 1000 : null,
                    marginBottom: 10,
                  }}
                  key={index}
                  onClick={() => selectVideo(index)} // Make the thumbnail clickable
                >
                  <div
                    style={{
                      border: "0px solid red",
                      width: "40%",
                    }}
                  >
                    <img
                      src={`${video.thumbnail}`}
                      style={{
                        border: "4px solid white",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        width: "95%",
                        borderRadius: 20,
                        opacity: selectedVideoIndex === index ? 0.5 : 1,
                      }}
                      alt="video thumbnail"
                    />
                  </div>
                  <div
                    style={{
                      border: "0px solid blue",
                      width: "60%",
                      paddingLeft: 10,
                    }}
                  >
                    <div style={{ borderBottom: "3px solid #C981F0" }}>
                      <h3
                        className={"bold"}
                        style={{
                          marginTop: 5,
                          color:
                            selectedVideoIndex === index ? "black" : "#82CFF0",
                          border: "0px solid black",
                          marginBottom: 0,
                          padding: 0,
                        }}
                      >
                        {video.title}
                      </h3>
                    </div>
                    <p
                      style={{
                        marginTop: 0,
                        border: "0px solid black",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 5, // Limits to 5 lines
                        textOverflow: "ellipsis", // Adds ellipses
                      }}
                    >
                      {video.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div style={{width: '100%', border: '0px solid red', display: 'flex', height: 140, justifyContent: 'flex-end'}}>
        <div
          style={{
            width: "100%",
            height: 100,
            // border: '3px solid red',
            backgroundColor: "#81CFF0" /* Soft blue background */,
            color: "white",
            fontSize: 14,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "auto",
            bottom: 0,
            // position: "relative",
            // border: '1px solid white'
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "15px",
              paddingBottom: "10px",
            }}
          >
            <a
              href="/TermsOfUse"
              style={{
                textDecoration: "none",
                color: "white",
                fontWeight: "bold",
                transition: "color 0.3s ease",
              }}
            >
              Terms of Use
            </a>
            <a
              href="/PrivacyPolicy"
              style={{
                textDecoration: "none",
                color: "white",
                fontWeight: "bold",
                transition: "color 0.3s ease",
              }}
            >
              Privacy Policy
            </a>
            <a
              href="/Contact"
              style={{
                textDecoration: "none",
                color: "white",
                fontWeight: "bold",
                transition: "color 0.3s ease",
              }}
            >
              Contact
            </a>
            <a
              href="/Support"
              style={{
                textDecoration: "none",
                color: "white",
                fontWeight: "bold",
                transition: "color 0.3s ease",
              }}
            >
              Support
            </a>
          </div>
          <div
            style={{
              color: "white",
            }}
          >
            <p style={{ margin: 0 }}>
              © {new Date().getFullYear()} NÜRI INC.{" "}
              <a
                href="https://nurieats.com"
                style={{ color: "white", textDecoration: "none" }}
              >
                nurieats.com
              </a>
            </p>
          </div>
        </div>
        </div>
        
      </div>
    )) || (
      <div
        style={{
          // width: "100", // Ensure it fills the viewport width
          // height: '100',
          display: "flex",
          justifyContent: "center",
          // paddingTop: 25,
          overflowX: "hidden", // Prevents horizontal scrolling within this container
          boxSizing: "border-box", // Ensures padding and border are included in total width
          overflowY: "scroll",
          position: "absolute",
        }}
      >
        <title>Nüri | Watch</title>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center", // Center items
            paddingTop: 10,
            // paddingTop: 5,
          }}
        >
          <div
            id={"watch"}
            style={{
              position: "relative",
              width: windowSize.innerWidth,
              paddingBottom: "56.25%", // Maintains 16:9 aspect ratio
              height: 0,
              width: window.innerWidth,
              // borderRadius: 30,
              overflow: "hidden",
              // border: "3px solid white",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)", // Adds the drop shadow
            }}
          >
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                // borderRadius: 30,
                // border: '3px solid red'
              }}
              src={`${selectedVideo.url}`} // Added autoplay parameter
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <p
            className={"bold"}
            style={{
              color: "#82CFF0",
              fontSize: 24,
              lineHeight: 1,
              textAlign: "left",
              marginTop: 25,
              marginBottom: 0,
              borderBottom: "5px solid #C981F0",
              paddingBottom: 5,
              marginLeft: 20,
              marginRight: 20,
              letterSpacing: -0.5,
              // Add padding top to create space between the text and the border
              paddingTop: 5, // Optional: Adjust based on your design preference
              // display: "inline-block", // Ensure the border and padding behave correctly
            }}
          >
            {`${selectedVideo.title}`}
          </p>

          <div
            style={{
              // height: 250,
              // border: "3px solid red",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <p
              style={{
                textAlign: "left",
                fontSize: 14,
                margin: 0, // Reset margin to prevent collapse
                paddingLeft: 20,
                paddingRight: 20,
                marginTop: 5,
                letterSpacing: -0.4,
              }}
            >
              {selectedVideo.description}
            </p>
          </div>

          <div style={{ width: "100%", marginTop: 20 }}>
            {videoList.map((video, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 10,
                  cursor: "pointer", // Changes cursor to hand on hover
                  backgroundColor:
                    selectedVideoIndex === index ? "#f7f7f7" : "white",
                  // borderRadius: 20,
                  boxShadow:
                    selectedVideoIndex === index
                      ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                      : null,
                  marginBottom: 10,
                }}
                key={index}
                onClick={() => selectVideo(index)} // Make the thumbnail clickable
              >
                <div style={{ width: "40%" }}>
                  <img
                    src={`${video.thumbnail}`}
                    style={{
                      border: "4px solid white",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      width: "95%",
                      borderRadius: 20,
                      opacity: selectedVideoIndex === index ? 0.5 : 1,
                    }}
                    alt="video thumbnail"
                  />
                </div>
                <div style={{ width: "60%", paddingLeft: 10 }}>
                  <div style={{ borderBottom: "3px solid #C981F0" }}>
                    <h3
                      className={"bold"}
                      style={{
                        marginTop: 5,
                        color:
                          selectedVideoIndex === index ? "black" : "#82CFF0",
                        marginBottom: 0,
                        padding: 0,
                        letterSpacing: -0.5,
                      }}
                    >
                      {video.title}
                    </h3>
                  </div>
                  <p
                    style={{
                      marginTop: 0,
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 5, // Limits to 5 lines
                      textOverflow: "ellipsis", // Adds ellipses
                      fontSize: 16,
                      letterSpacing: -0.4,
                      paddingRight: 25,
                      paddingTop: 5,
                    }}
                  >
                    {video.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <footer
            style={{
              position: "relative",
              width: "100%",
              backgroundColor: "#81CFF0",
              color: "white",
              textAlign: "center",
              padding: "20px 0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "14px",
              marginTop: "50px", // Adjust this to add space between the content and footer
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: "15px",
                paddingBottom: "10px",
              }}
            >
              <Link
                to="/TermsOfUse"
                style={{ textDecoration: "none", color: "white" }}
              >
                Terms of Use
              </Link>
              <Link
                to="/PrivacyPolicy"
                style={{ textDecoration: "none", color: "white" }}
              >
                Privacy Policy
              </Link>
              <Link
                to="/Contact"
                style={{ textDecoration: "none", color: "white" }}
              >
                Contact
              </Link>
              <Link
                to="/Support"
                style={{ textDecoration: "none", color: "white" }}
              >
                Support
              </Link>
            </div>
            <div
              style={{
                color: "white",
              }}
            >
              <p style={{ margin: 0 }}>
                © {new Date().getFullYear()} NÜRI INC.{" "}
                <a
                  href="https://nurieats.com"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  nurieats.com
                </a>
              </p>
            </div>
          </footer>
        </div>
      </div>
    )
  );
}

let styles = {
  container: {
    width: "100%",
    // height: "100vh",
    display: "flex",
    justifyContent: "center",
    paddingTop: 25,
  },
};

export default Watch;
