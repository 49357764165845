import React from "react";
import logo from "./assets/logo-icons/Icon-8.png";
import logo0 from "./assets/logo-icons/icon_20pt@2x.png";
import logo1 from "./assets/logo-icons/icon_20pt@3x.png";
import logo2 from "./assets/logo-icons/icon_29pt@2x.png";
import logo3 from "./assets/logo-icons/icon_29pt@3x.png";
import logo4 from "./assets/logo-icons/icon_40pt@2x.png";
import logo5 from "./assets/logo-icons/icon_40pt@3x.png";
import logo6 from "./assets/logo-icons/icon_60pt@2x.png";
import logo7 from "./assets/logo-icons/icon_60pt@3x.png";
import nuriAd0 from "./assets/nuri_ad/nuri_logo_now_available_legal.png";
import nuriAd1 from "./assets/nuri_ad/nuri_logo_tm_bottom.png";
import "./App.css";
import "./index.css";
import { Helmet } from "react-helmet";
import { Route, Link } from 'react-router-dom';

const images = require.context(
  "./assets/misc_images",
  false,
  /\.(png|jpe?g|gif)$/
);
const foodIconImages = require.context(
  "./assets/food_icons",
  false,
  /\.(png|jpe?g|gif)$/
);

const appstoreScreenshots = require.context(
  "./assets/appstore_screenshots",
  false,
  /\.(png|jpe?g|gif)$/
);

function BrandingGuidelines() {
  const imageFiles = images.keys(); // Get all the file names in the folder
  const foodIconImageFiles = foodIconImages.keys(); // Get all the file names in the food icon folder
  const screenshots = appstoreScreenshots.keys(); // Get all the file names in the food icon folder
  return (
    <div style={styles.container}>
      <Helmet>
        <html lang="en" />
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="Nüri Inc." />
        <meta
          name="description"
          content="Connect with talented home cooks and discover delicious, homemade meals tailored to your taste."
        />
        <title>Nüri | Branding Guidelines</title>
      </Helmet>

      <div className="branding-guidelines">
        <header>
          <h1>Nüri Branding Guidelines & Assets</h1>
          <p>
            Follow these guidelines to ensure consistent and professional brand
            representation.
          </p>
        </header>

        <section className="logo-section">
          <h2>Logo & Font Usage</h2>
          <h3>Clear Space & Minimum Size</h3>
          <p>
            Ensure a minimum size of 50px width for digital and 1.5 inches for
            print.
          </p>

          <h3>Logo and Font Restrictions</h3>
<p>To maintain brand consistency, you are not permitted to:</p>
<ul>
  <li>Alter, filter, stretch, or distort the logo or fonts in any way.</li>
  <li>Change the color scheme of the logo in any way.</li>
  <li>Use the logo as a part of other logos or graphics.</li>
  <li>
    Rotate, animate, or apply any visual effects to the logo or fonts.
  </li>
  <li>
    Use the logo in a manner that could cause confusion or
    misrepresentation of the brand.
  </li>
  <li>Change or alter the fonts in any way.</li>
</ul>

<h3>Font Usage Guidelines</h3>
<p>
  The primary font for the Nüri brand is <strong>Century Gothic Bold</strong>, and the secondary font is <strong>Century Gothic</strong>.
</p>
<p>
  To ensure consistency, these fonts should not be altered, distorted, or modified in any way. They should be used in their default form, with no changes to their weight, style, or proportions.
</p>

<h2>App Logos</h2>
<img
                  src={nuriAd1}
                  alt={`Nüri Logo`}
                  className="logo-preview"
                  style={{width: 500}}
                />
                <img
                  src={nuriAd0}
                  alt={`Nüri Logo`}
                  className="logo-preview"
                  style={{width: 500}}
                />

<h2>App Icons</h2>
{/* <p>{"Right click > Open Image in New Tab to download the image."}</p> */}
          <div style={{display: 'flex', justifyContent: 'flex-start'}} className="logo-grid">
            {[
              { src: logo0, label: "icon_20pt@2x.png" },
              { src: logo1, label: "icon_20pt@3x.png" },
              { src: logo2, label: "icon_29pt@2x.png" },
              { src: logo3, label: "icon_29pt@3x.png" },
              { src: logo4, label: "icon_40pt@2x.png" },
              { src: logo5, label: "icon_40pt@3x.png" },
              { src: logo6, label: "icon_60pt@2x.png" },
              { src: logo7, label: "icon_60pt@3x.png" },
              { src: logo, label: "Icon-8.png" },
            ].map((logo, index) => (
              <div className="logo-item" key={index}>
                <img
                  src={logo.src}
                  alt={`Nüri Logo ${logo.label}`}
                  className="logo-preview"
                />
                <p className="logo-label">{logo.label}</p>
              </div>
            ))}
          </div>
        </section>

        <h2>AppStore Screenshots</h2>
          <div style={{display: 'flex', justifyContent: 'flex-start'}} className="logo-grid">
            {screenshots.map((fileName, index) => {
              const image = appstoreScreenshots(fileName); // Dynamically import image
              return (
                <div className="logo-item" key={index}>
                  <img
                    src={image}
                    alt={`Nüri Logo ${fileName}`}
                    className="logo-preview"
                  />
                  <p className="logo-label">{fileName}</p>
                </div>
              );
            })}
          </div>

        <section className="color-palette">
          <h2>Color Palette</h2>
          <h3>Primary Colors</h3>
          <div className="color-grid">
            {Object.entries(mainColors).map(([name, hex], index) => (
              <div
                key={index}
                className="color-swatch"
                style={{ background: hex, padding: 5 }}
              >
                <p>{name}</p>
                <p>HEX: {hex}</p>
              </div>
            ))}
          </div>
          <h3>Secondary Colors</h3>
          <div className="color-grid">
            {Object.entries(colors).map(([name, hex], index) => (
              <div
                key={index}
                className="color-swatch"
                style={{ background: hex, padding: 5 }}
              >
                <p>{name}</p>
                <p>HEX: {hex}</p>
              </div>
            ))}
          </div>

          

          <h2>Food Icons</h2>

          <div style={{display: 'flex', justifyContent: 'flex-start'}} className="logo-grid">
            {foodIconImageFiles.map((fileName, index) => {
              const image = foodIconImages(fileName); // Dynamically import image using foodIconImages
              return (
                <div className="logo-item" key={index}>
                  <img
                    src={image}
                    alt={`Food Icon ${fileName}`}
                    className="logo-preview"
                  />
                </div>
              );
            })}
          </div>

          <h2>Food Category Images</h2>
          <div style={{display: 'flex', justifyContent: 'flex-start'}} className="logo-grid">
            {imageFiles.map((fileName, index) => {
              const image = images(fileName); // Dynamically import image
              return (
                <div className="logo-item" key={index}>
                  <img
                    src={image}
                    alt={`Nüri Logo ${fileName}`}
                    className="logo-preview"
                  />
                  <p className="logo-label">{fileName}</p>
                </div>
              );
            })}
          </div>
        </section>
      </div>
   
    </div>
  );
}

const mainColors = {
  BLUE: "#81CFF0",
  PURPLE: "#C981F0",
  ORANGE: "#F0A481",
  ALMOST_BLACK: "#404040",
  RED: "#DF0303",
  GREEN: "#10BC19",
  DARK_GRAY: "#717171",
  MEDIUM_GRAY: "#A6A6A6",
};

const colors = {
  INDIGO: "#6A5ACD",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  DARK_BLUE: "#7E91D2",
  BABY_BLUE: "#acbdfc",
  DEEP_ORANGE: "#FF7F50",
  LIGHT_ORANGE: "#EDC9B9",
  YELLOW: "#F0DC81",
  PINK: "rgba(255, 97, 239, 1)",
  LIGHT_PURPLE: "#D8B4ED",
  LIGHT_GREEN: "#83e688",
  LIGHT_GRAY: "#CCCCCC",
  SAFE_AREA_LIGHT: "#FFFFFF",
  SAFE_AREA_DARK: "#7E91D2",
  WHEAT: "#dbb374",
  DAIRY: "#81CFF0",
  EGGS: "#F0DC81",
  FISH: "#3b55ed",
  SHELLFISH: "#F0A481",
  TREE_NUTS: "#ad7c2e",
  PEANUTS: "#c98d54",
  SOYBEANS: "#edd38e",
  SESAME: "#404040",
  SULPHITES: "#4b7f4e",
  SILVER: "#ededed",
  LIGHT_AQUA: "#9cf4e0",
  STRIPE: "#635AFF",
};

const styles = {
  container: {
    width: "100%",
    padding: "2rem",
    fontFamily: "'Open Sans', sans-serif",
    padding: 30,
  },
};

export default BrandingGuidelines;
