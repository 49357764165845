import React, { useState, useEffect } from 'react';
import { Route, Link } from 'react-router-dom';
import '../App.css';

function NavBar(props) {

  
  const [navBarOptions, setNavBarOptions] = useState(['home', 'watch', 'privacy_policy', 'contact', 'support']);
  const [selectedButton, setSelectedButton] = useState();
  
  useEffect(() => {
    setSelectedButton(props.currentScreen);
  },[props.currentScreen]);

  const [button0Style, setButton0Style] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [button1Style, setButton1Style] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [button2Style, setButton2Style] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [button3Style, setButton3Style] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    fontSize: 20,
  });
  const [button4Style, setButton4Style] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [buttonStyleNormal] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    borderWidth: 1,
    fontSize: 20,
  });

  const [buttonStyleHover] = useState({
    backgroundColor: 'white',
    border: '0px',
    // borderTopWidth: 0,
    // borderLeftWidth: 0,
    // borderBottomWidth: 0,
    color: '#C981F0',
    height: 33,
    width: 200,
    borderWidth: 1,
    fontSize: 20,
    borderRadius: 10,
  });

  function mouseHover(buttonIndex) {
    if(buttonIndex === 0) {
      setButton0Style(buttonStyleHover);
    }
    else if(buttonIndex === 1) {
      setButton1Style(buttonStyleHover);
    }
    else if(buttonIndex === 2) {
      setButton2Style(buttonStyleHover);
    }
    else if(buttonIndex === 3) {
      setButton3Style(buttonStyleHover);
    }
    else if(buttonIndex === 4) {
      setButton4Style(buttonStyleHover);
    }
  };

  function mouseOut(buttonIndex) {
    if(buttonIndex === 0) {
      setButton0Style(buttonStyleNormal);
    }
    else if(buttonIndex === 1) {
      setButton1Style(buttonStyleNormal);
    }
    else if(buttonIndex === 2) {
      setButton2Style(buttonStyleNormal);
    }
    else if(buttonIndex === 3) {
      setButton3Style(buttonStyleNormal);
    }
    else if(buttonIndex === 4) {
      setButton4Style(buttonStyleNormal);
    }
  };

  return (
 
      <div style={styles.navBar}>
        <Link to={'/'} >
          <button onMouseOver={() => mouseHover(0)} onMouseOut={() => mouseOut(0)}  style={button0Style} onClick={() => setSelectedButton('home')}>
            <div style={{cursor: 'pointer'}} className={selectedButton === 'home' ? 'bold' : null}>Home</div>
          </button>
        </Link>

        <Link to="/Watch" >
          <button onMouseOver={() => mouseHover(1)} onMouseOut={() => mouseOut(1)}  style={button1Style} onClick={() => setSelectedButton('watch')}>
            <div style={{cursor: 'pointer'}} className={selectedButton === 'watch' ? 'bold' : null}>Watch</div>
          </button>
        </Link>

        <Link to="/PrivacyPolicy" >
          <button onMouseOver={() => mouseHover(2)} onMouseOut={() => mouseOut(2)}  style={button2Style} onClick={() => setSelectedButton('privacy_policy')}>
            <div style={{cursor: 'pointer'}} className={selectedButton === 'privacy_policy' ? 'bold' : null}>Privacy Policy</div>
          </button>
        </Link>

        <Link to="/Contact" >
          <button onMouseOver={() => mouseHover(3)} onMouseOut={() => mouseOut(3)}  style={button3Style} onClick={() => setSelectedButton('contact')}>
            <div style={{cursor: 'pointer'}} className={selectedButton === 'contact' ? 'bold' : null}>Contact</div>
          </button>
        </Link>

        <Link to="/Support" >
          <button onMouseOver={() => mouseHover(4)} onMouseOut={() => mouseOut(4)}  style={button4Style} onClick={() => setSelectedButton('support')}>
            <div style={{cursor: 'pointer'}} className={selectedButton === 'support' ? 'bold' : null}>Support</div>
          </button>
        </Link>

        
        {/* <button onMouseOver={() => mouseHover(1)} onMouseOut={() => mouseOut(1)}  style={button1Style} onClick={() => console.log()}>
          Privacy Policy
        </button> */}
      </div>
    
  );

  

}

let styles = {
  navBar: {
    // position: 'fixed',
    // border: '3px solid purple',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#C981F0',
    width: '100%',
    padding: 10,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Adds the drop shadow
  },
  navBarMobile: {
    // position: 'fixed',
    // border: '3px solid purple',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#C981F0',
    // width: '100%',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: 10,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Adds the drop shadow
  },
};

export default NavBar;