import React, { useState, useEffect } from 'react';
import logo from './assets/logo-icons/Icon-8.png';
import './App.css';
import './index.css';
import { Route, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ReactComponent as AppleAppStoreBadge } from './assets/images/app-store-badge.svg';
import backgroundImage from './assets/UI/Backgrounds/Welcome/bg1.jpg';
import backgroundImage2 from './assets/UI/Backgrounds/Welcome/bg2.jpg';
import backgroundImage3 from './assets/UI/Backgrounds/Welcome/bg2.jpg';
import backgroundImage4 from './assets/UI/Backgrounds/Welcome/bg2.jpg';
import backgroundImage5 from './assets/UI/Backgrounds/Welcome/bg2.jpg';
import backgroundImage6 from './assets/UI/Backgrounds/Welcome/bg2.jpg';
import backgroundImage7 from './assets/UI/Backgrounds/Welcome/bg2.jpg';
import backgroundImage8 from './assets/UI/Backgrounds/Welcome/bg8.jpg';
import iphoneImage from './assets/UI/Backgrounds/Welcome/iphone.png';
import canadaFlag from './assets/images/flags/ca.png';
import usaFlag from './assets/images/flags/usa.png';

function Home(props) {

  const [readMoreVisible, setReadMoreVisible] = useState(false);

  useEffect(() => {
    props.setCurrentScreen('home');
  },[]);

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="Nüri Inc." />
        <meta name="description" content="Connect with talented home cooks and discover delicious, homemade meals tailored to your taste. Ready for pickup or delivered straight to your door, enjoy fresh, local food at your convenience." />
        <title>Nüri | Home</title>
      </Helmet>
      {props.windowSize.innerWidth >= 900 && (
      <div style={styles.container} >

        <div style={styles.body}>

          <div style={styles.panelContainer}>
          

            <div style={styles.leftPanel}>
              
            </div>

            <div style={styles.rightPanel}>

              <div style={{height: 50, width: '100%', border: '0px solid red', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                {/* <h2 style={{textAlign: 'center', color: '#4a4a4a', fontSize: 40, fontWeight: 'bold'}} >{'The official marketplace for home-cooked foods!'}</h2> */}
              </div>
              
              {/* <div style={{ border: '0px solid red', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={styles.logo} src={logo} />
              </div> */}
              <div style={{ border: '0px solid red', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img
                  style={styles.logo}
                  src={logo}
                  alt="Nüri Logo - Connecting foodies with talented home cooks for fresh, local meals"
                  title="Nüri Logo"
                  // width="200"  // Specify the width for better optimization (adjust as needed)
                  height="auto" // Keep aspect ratio consistent
                />
              </div>
              
              <div style={{display: 'flex', flexDirection: 'column', width: '100%', border: '0px solid red', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                  <p style={styles.textWithShadow} >{'Available now for'}</p>
                  <p style={styles.textWithShadow} >{'iOS & Android'}</p>
  
                  <div style={{boxShadow: '0 6px 8px rgba(0, 0, 0, 0.3)', backgroundColor: 'white', borderRadius: 20, marginTop: 10, padding: 15, textAlign: 'center' }}>
                    
                    
                    
                  <div style={{alignItems: 'center', display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                      
                      
                      <img style={{ width: 30, height: 20 }} src={canadaFlag} alt="Canada Flag" />
                      <p 
                      style={{
                        color: '#12ba4a',
                        fontWeight: 'bold',
                        margin: 0,
                        marginLeft: 10,
                        whiteSpace: 'nowrap',       // Prevent text from wrapping to a new line
                        overflow: 'hidden',         // Hide text that exceeds the container width
                        textOverflow: 'ellipsis',
                        
                      }} 
                      >
                        Open to Canadian users!
                      </p>
                      
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <img style={{ width: 28, height: 18 }} src={usaFlag} alt="USA Flag" />
                      <p style={{ color: '#12ba4a', fontWeight: 'bold', margin: 0, marginLeft: 10 }} >
                        Open to U.S. users!
                      </p>
                    </div>
                  </div>

                    
                  </div>

              </div>

              <div style={{padding: 0, flexDirection: 'row', border: '0px solid orange', display: 'flex', flexDirection: 'row'}}>

                {/* <a href="https://apps.apple.com/ca/app/n%C3%BCri/id1599369090" style={{width: '50%', border: '0px solid blue', alignItems: 'center', justifyContent: 'right', display: 'flex'}}>
                   
                  <AppleAppStoreBadge style={{margin: 0, width: '70%', maxWidth: 420, height: 100, }} />
                  
                </a> */}
                <a 
                  href="https://apps.apple.com/ca/app/n%C3%BCri/id1599369090" 
                  style={{ width: '50%', border: '0px solid blue', alignItems: 'center', justifyContent: 'right', display: 'flex' }}
                  aria-label="Download Nüri from the Apple App Store"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AppleAppStoreBadge 
                    style={{ margin: 0, width: '70%', maxWidth: 420, height: 100 }} 
                    alt="Download on the Apple App Store"
                  />
                </a>


                {/* <a href="https://play.google.com/store/apps/details?id=com.nuri_app" style={{width: '50%', border: '0px solid green', alignItems: 'center', justifyContent: 'left', display: 'flex'}}>
                  <img style={{margin: 0, width: '90%', maxWidth: 520}} src={require('./assets/images/google-play-badge.png')} />
                </a> */}
                <a 
                  href="https://play.google.com/store/apps/details?id=com.nuri_app" 
                  style={{ width: '50%', border: '0px solid green', alignItems: 'center', justifyContent: 'left', display: 'flex' }}
                  aria-label="Download Nüri from the Google Play Store"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img 
                    style={{ margin: 0, width: '90%', maxWidth: 520 }} 
                    src={require('./assets/images/google-play-badge.png')} 
                    alt="Get it on Google Play"
                  />
                </a>


              </div>

              <div style={{width: '100%', border: '0px solid red', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                <p style={{color: 'black', fontSize: 14, textAlign: 'center', margin: 3}}>Apple and the Apple logo are trademarks of Apple Inc., registered  in the U.S. and other countries.</p>
                <p style={{color: 'black', fontSize: 14, textAlign: 'center', margin: 3}}>App Store is a service mark of Apple Inc. iOS is a trademark or registered trademark of Cisco in the U.S. and other countries and is used under license.</p>
                <p style={{color: 'black', fontSize: 14, textAlign: 'center', margin: 3}}>Google Play and the Google Play logo are trademarks of Google LLC.</p>
              </div>

              

            </div>

            

          </div>

          {/* <div style={styles.infoPanel}>
            <div style={styles.infoPanelOverlay}>
              <h2 style={{textAlign: 'center', fontSize: 50, marginBottom: 0, marginTop: 0, color: '#4fb6e2'}} >Nüri is the official marketplace for home cooked foods!</h2>
              <p style={{color: '#4d4d4d', fontSize: 24, textAlign: 'center', marginTop: 0, marginBottom: 0}} >
                The essence of the Nüri app is to bring together individuals who have a passion and gift for cooking from home
                and connecting them with those who are just as passionate about eating home cooked food.
              </p>
      
          
            </div>
            
          </div> */}
          <section style={styles.infoPanel}>
  <div style={styles.infoPanelOverlay}>
    <h1 style={{ textAlign: 'center', fontSize: 50, marginBottom: 0, marginTop: 0, color: '#4fb6e2' }}>
      Nüri is the official marketplace for home-cooked foods!
    </h1>
    <p style={{ color: '#4d4d4d', fontSize: 24, textAlign: 'center', marginTop: 0, marginBottom: 0 }}>
      The essence of the Nüri app is to bring together individuals who have a passion and gift for cooking from home
      and connect them with those who are just as passionate about eating home-cooked food.
    </p>
  </div>
</section>

          


        </div>
        {/* <div style={{height: 'auto', marginBottom: 10, border: '0px solid black'}}>
          {readMoreVisible === false && (
            <div style={{border: '0px solid black', marginTop: 0}} onClick={() => setReadMoreVisible(true)}>
              <p style={{color: '#4fb6e2', fontWeight: 'bold', fontSize: 24, textAlign: 'center', marginTop: 0, marginBottom: 0, cursor: 'pointer'}} >Read more...</p>
            </div>
          )}
          {readMoreVisible === true && (
            <div style={styles.readMore} >
              <p>
                Enter the authentic experience of home-cooked meals. If you’re an adventurous foodie who seeks meals from various cultures around the world or just looking for some homemade comfort food that reminds you of a special time or place, Nüri opens a world of delicious foods prepared by home cooks who are just as passionate about cooking as you are about eating.
                <br/><br/>
                Nüri provides home cooks an opportunity to become entrepreneurs, selling the foods they love to prepare. Foodies are looking to buy foods that are made with authenticity and passion. As a Cook, if you’ve been told, “people would pay for this meal”, then Nüri is an app that allows you to market your delicious, mouthwatering food with pictures and descriptions of the foods you prepare. Cooks will create their own personal profile that will allow foodies to know a little about the person cooking the food and perhaps develop a relationship with the customers. The pictures will be uploaded to the profile and be seen by any foodie searching for your type of food and in your area. Choose your pictures wisely as ‘we eat with our eyes first’.
                <br/><br/>
                Foodies will place their order through the app and will receive a notification that an order has been placed. Cooks will set their hours of operation so orders will only be received when they’re available. They’ll also set the price for the food and the time it will take to prepare the food. This information will be seen by the foodie when placing the order. Cooks will want to grow their customer base, so prices should reflect a fair market price, allowing for profit. When ordering the food, the foodie will provide credit card information for the purchase. The transaction will be complete when the food is either picked up or delivered, so no cash will change hands. All payments go through the app. Cooks will have the option, on their profile, to either deliver the food to customers or have them pick it up. If delivery is chosen, a delivery fee is added to the cost. 
                <br/><br/>
                Nüri does not provide an option for tipping.  Cooks are encouraged to price fairly and not ask for or expect a tip. Customers will appreciate this and are more likely to return and tell others. 
                <br/><br/>
                Nüri allows cooks to track their sales to watch their income grow. Whether your cooking skills are wide-ranging or you have only one amazing specialty dish, let foodies choose and delight in your culinary skills!
                <br/><br/>
                Foodies will have the opportunity to rate the food. The higher the ratings for your food,  the more likely your customer base will increase.  As foodies search for foods in their geographic area, they’ll see the ratings of various cooks. There are some fabulous home cooks out there who should be recognized. The quality of the food will speak for itself!
                <br/><br/>
                When searching, foodies will have the option to select foods by ethnic type such as Greek, Chinese, Italian, Mexican, Caribbean, etc., or by category such as vegan, gluten free, comfort food, BBQ, desserts. Special Dietary meals can also be requested like Keto, Gluten-Free, Halal. Foodies will see a map indicating the location of those cooks and their specialties. As the number of cooks increases so will the options for different kinds of food.
                <br/><br/>
                Foodies will also set up a profile, including a payment method. Once that’s done, they’re ready to enter our kitchen for home cooked foods in their area.
                <br/><br/>
                Foodies may even want to follow a specific cook or want to remember a meal previously ordered on Nüri. A quick look in their Order history will provide that information. To search by location, use our map to find cooks wherever you are. If Nüri has a cook in that area, they will pop onto the map screen.
                <br/><br/>
                Nüri is an app that introduces passionate home cooks to people just as passionate about eating great foods, opening a world of delicious foods created right in your local area. Turn your joy and passion for cooking into a thriving business, Nüri will showcase your dishes to hungry foodies. We invite you to Enter our Kitchen!
              </p>
              <div style={{border: '0px solid black', }} onClick={() => setReadMoreVisible(false)}>
                <p style={{color: '#4fb6e2', fontWeight: 'bold', fontSize: 24, textAlign: 'center', marginTop: 0, marginBottom: 0, cursor: 'pointer'}} >Read less...</p>
              </div>
            </div>
          )}
        </div> */}
        <div style={{ height: 'auto', marginBottom: 10, border: '0px solid black' }}>
  {!readMoreVisible && (
    <div style={{ border: '0px solid black', marginTop: 0 }} onClick={() => setReadMoreVisible(true)}>
      <p style={{ color: '#4fb6e2', fontWeight: 'bold', fontSize: 24, textAlign: 'center', margin: 0, cursor: 'pointer' }}>
        Read more...
      </p>
    </div>
  )}

  {readMoreVisible && (
    <div style={styles.readMore}>
      <p>
        <strong>Cooks can:</strong>
        <br />
        - Turn their love for cooking into a business by selling homemade meals.
        <br />
        - Create a personal profile showcasing their culinary skills and photos of their dishes.
        <br />
        - Set hours of operation, prices, and prep times for their meals.
        <br />
        - Decide whether to offer pickup or delivery options (with a delivery fee).
        <br />
        - Track their sales and monitor income growth.
        <br />
        - Build a customer base with high-quality food and competitive pricing.
        <br />
        - Receive ratings from foodies, helping attract more customers.
        <br />
        <br />
        <strong>Foodies can:</strong>
        <br />
        - Discover and order authentic, home-cooked meals from local cooks.
        <br />
        - Search by cuisine type (e.g., Greek, Chinese, Vegan) or dietary needs (Keto, Gluten-Free, Halal).
        <br />
        - View cook profiles, complete with photos, descriptions, and customer ratings.
        <br />
        - Place orders through the app using a secure payment system—no cash required.
        <br />
        - Follow favorite cooks or reorder meals via order history.
        <br />
        - Use a map to find cooks and meals available nearby.
        <br />
        <br />
        Nüri connects passionate home cooks with foodies eager for great, home-cooked meals. Whether you're a cook looking to share your talent or a foodie searching for comfort food, Nüri is here to make those connections seamless and rewarding. Enter our Kitchen and start your culinary journey today!
      </p>
      <div style={{ border: '0px solid black' }} onClick={() => setReadMoreVisible(false)}>
        <p style={{ color: '#4fb6e2', fontWeight: 'bold', fontSize: 24, textAlign: 'center', margin: 0, cursor: 'pointer' }}>
          Read less...
        </p>
      </div>
    </div>
  )}
</div>

        {/* <header>
          <title>Nuri</title>
        </header>
        
        <body>
          <h1>This is a heading</h1>
          <p>This is a paragraph.</p>
        </body> */}
        <footer style={{
          position: 'relative',
          width: '100%',
          backgroundColor: '#81CFF0',
          color: 'white',
          textAlign: 'center',
          padding: '20px 0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '14px',
          marginTop: '50px' // Adjust this to add space between the content and footer
      }}>
          <div style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: '15px',
              paddingBottom: '10px'
          }}>
              <Link to="/TermsOfUse" style={{ textDecoration: 'none', color: 'white' }}>Terms of Use</Link>
              <Link to="/PrivacyPolicy" style={{ textDecoration: 'none', color: 'white' }}>Privacy Policy</Link>
              <Link to="/Contact" style={{ textDecoration: 'none', color: 'white' }}>Contact</Link>
              <Link to="/Support" style={{ textDecoration: 'none', color: 'white' }}>Support</Link>
          </div>
          <div style={{
              color: 'white'
          }}>
              <p style={{ margin: 0 }}>© {new Date().getFullYear()} NÜRI INC. <a href="https://nurieats.com" style={{ color: 'white', textDecoration: 'none' }}>nurieats.com</a></p>
          </div>
      </footer>
      </div>
    )
    ||
    (
      <div style={styles.container} >

        <div style={styles.bodyMobile}>

          <div style={styles.panelContainerMobile}>
          
            <div style={styles.topPanel} >
              
            </div>

            <div style={styles.topPanelOverlap}>
              {/* <div style={{height: 'auto', width: '100%', border: '0px solid red', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                <p tyle={{textAlign: 'center', fontSize: 40}} >Some content here...</p>
              </div> */}
              {/* <div style={{height: 340, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={styles.logoMobile} src={logo} />
              </div> */}
              <div style={{height: 340, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img
                  style={styles.logoMobile}
                  src={logo}
                  alt="Nüri Logo - Connecting foodies with talented home cooks for fresh, local meals"
                  title="Nüri Logo"
                  // width="200"  // Specify the width for better optimization (adjust as needed)
                  height="auto" // Keep aspect ratio consistent
                />
              </div>
              
              <div style={{marginTop: -15, display: 'flex', flexDirection: 'column', width: '100%', border: '0px solid red', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                {/* <h2 style={styles.textWithShadow} >{'The Official Marketplace for Homecooked Foods!'}</h2> */}
                <h2 className={'alternateFont'} style={styles.textWithShadowMobile} >{'Available now for'}</h2>
                <h2 className={'alternateFont'} style={styles.textWithShadowMobile} >{'iOS & Android'}</h2>
              </div>
              
              {/* <div style={{border: '0px solid red', width: '100%', }} >
                <div style={{margin: 'auto', width: '70%', boxShadow: '0 6px 8px rgba(0, 0, 0, 0.3)', backgroundColor: 'white', borderRadius: 20, padding: 15, textAlign: 'center' }}>
                  <p style={{ color: '#12ba4a', fontWeight: 'bold', margin: 0 }} >🇨🇦 Currently open to Canadian users!</p>
                  <p style={{ color: 'grey', fontWeight: 'bold', margin: 0 }} >🇺🇸 U.S. coming soon!</p>
                </div>
              </div> */}
              <div style={{margin: 'auto', width: '70%', boxShadow: '0 6px 8px rgba(0, 0, 0, 0.3)', backgroundColor: 'white', borderRadius: 20, padding: 15, textAlign: 'center' }}>
                <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                    <img style={{ width: 30, height: 20 }} src={canadaFlag} alt="Canada Flag" />
                    <p className={'alternateFont'} style={{ color: '#12ba4a', fontWeight: 'bold', margin: 0, marginLeft: 10, letterSpacing: -1 }} >
                      Open to Canadian users!
                    </p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img style={{ width: 28, height: 18 }} src={usaFlag} alt="USA Flag" />
                    <p className={'alternateFont'} style={{ color: '#12ba4a', fontWeight: 'bold', margin: 0, marginLeft: 10, letterSpacing: -1 }} >
                    Open to U.S. users!
                    </p>
                  </div>
                </div>
              </div>

              <div style={{flexDirection: 'row', border: '0px solid orange', display: 'flex', flexDirection: 'row'}}>

                {/* <a href="https://apps.apple.com/ca/app/n%C3%BCri/id1599369090" style={{width: '50%', border: '0px solid blue', alignItems: 'center', justifyContent: 'right', display: 'flex'}}>
                  <AppleAppStoreBadge style={{ width: '70%', maxWidth: 420, height: 100, }} />
                  <div style={{width: '9%'}} />
                </a> */}
                <a 
                  href="https://apps.apple.com/ca/app/n%C3%BCri/id1599369090" 
                  style={{ width: '50%', border: '0px solid blue', alignItems: 'center', justifyContent: 'right', display: 'flex' }}
                  aria-label="Download Nüri from the Apple App Store"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AppleAppStoreBadge 
                    style={{ margin: 0, width: '70%', maxWidth: 420, height: 100 }} 
                    alt="Download on the Apple App Store"
                  />
                  <div style={{width: '9%'}} />
                </a>


                {/* <a href="https://play.google.com/store/apps/details?id=com.nuri_app" style={{width: '50%', border: '0px solid green', alignItems: 'center', justifyContent: 'left', display: 'flex'}}>
                  <img style={{margin: 0, width: '90%', maxWidth: 520}} src={require('./assets/images/google-play-badge.png')} />
                </a> */}
                <a 
                href="https://play.google.com/store/apps/details?id=com.nuri_app" 
                style={{ width: '50%', border: '0px solid green', alignItems: 'center', justifyContent: 'left', display: 'flex' }}
                aria-label="Download Nüri from the Google Play Store" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <img 
                  style={{ margin: 0, width: '90%', maxWidth: 520 }} 
                  src={require('./assets/images/google-play-badge.png')} 
                  alt="Get it on Google Play"
                />
              </a>


              </div>

              <div style={{width: '100%', border: '0px solid red', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                <p style={{color: 'black', fontSize: 14, textAlign: 'center', margin: 3}}>Apple and the Apple logo are trademarks of Apple Inc., registered  in the U.S. and other countries.</p>
                <p style={{color: 'black', fontSize: 14, textAlign: 'center', margin: 3}}>App Store is a service mark of Apple Inc. iOS is a trademark or registered trademark of Cisco in the U.S. and other countries and is used under license.</p>
                <p style={{color: 'black', fontSize: 14, textAlign: 'center', margin: 3}}>Google Play and the Google Play logo are trademarks of Google LLC.</p>
              </div>
            </div>
            

          </div>

          {/* <div style={styles.infoPanelMobile}>
            <div style={styles.infoPanelOverlayMobile}>
              <p className={'alternateFont'} style={{textAlign: 'center', fontSize: 50, lineHeight: 1, marginBottom: 0, marginTop: 150, color: '#4fb6e2', fontWeight: 'bold'}} >Nüri is the official marketplace for home cooked foods!</p>
              <p style={{color: '#4d4d4d', fontSize: 24, textAlign: 'center', marginTop: 0, marginBottom: 0}} >
                The essence of the Nüri app is to bring together individuals who have a passion and gift for cooking from home<br/>
                and connecting them with those who are just as passionate about eating home cooked food.
              </p>
  
              
              {readMoreVisible === false && (
                <div style={{border: '0px solid black', marginTop: 3 }} onClick={() => setReadMoreVisible(true)}>
                  <p style={{color: '#4fb6e2', fontWeight: 'bold', fontSize: 24, textAlign: 'center', marginTop: 0, marginBottom: 0, cursor: "pointer"}} >Read more...</p>
                </div>
              )}
              {readMoreVisible === true && (
                <p>
                  Enter the authentic experience of home-cooked meals. If you’re an adventurous foodie who seeks meals from various cultures around the world or just looking for some homemade comfort food that reminds you of a special time or place, Nüri opens a world of delicious foods prepared by home cooks who are just as passionate about cooking as you are about eating.
                  <br/><br/>
                  Nüri provides home cooks an opportunity to become entrepreneurs, selling the foods they love to prepare. Foodies are looking to buy foods that are made with authenticity and passion. As a Cook, if you’ve been told, “people would pay for this meal”, then Nüri is an app that allows you to market your delicious, mouthwatering food with pictures and descriptions of the foods you prepare. Cooks will create their own personal profile that will allow foodies to know a little about the person cooking the food and perhaps develop a relationship with the customers. The pictures will be uploaded to the profile and be seen by any foodie searching for your type of food and in your area. Choose your pictures wisely as ‘we eat with our eyes first’.
                  <br/><br/>
                  Foodies will place their order through the app and will receive a notification that an order has been placed. Cooks will set their hours of operation so orders will only be received when they’re available. They’ll also set the price for the food and the time it will take to prepare the food. This information will be seen by the foodie when placing the order. Cooks will want to grow their customer base, so prices should reflect a fair market price, allowing for profit. When ordering the food, the foodie will provide credit card information for the purchase. The transaction will be complete when the food is either picked up or delivered, so no cash will change hands. All payments go through the app. Cooks will have the option, on their profile, to either deliver the food to customers or have them pick it up. If delivery is chosen, a delivery fee is added to the cost. 
                  <br/><br/>
                  Nüri does not provide an option for tipping.  Cooks are encouraged to price fairly and not ask for or expect a tip. Customers will appreciate this and are more likely to return and tell others. 
                  <br/><br/>
                  Nüri allows cooks to track their sales to watch their income grow. Whether your cooking skills are wide-ranging or you have only one amazing specialty dish, let foodies choose and delight in your culinary skills!
                  <br/><br/>
                  Foodies will have the opportunity to rate the food. The higher the ratings for your food,  the more likely your customer base will increase.  As foodies search for foods in their geographic area, they’ll see the ratings of various cooks. There are some fabulous home cooks out there who should be recognized. The quality of the food will speak for itself!
                  <br/><br/>
                  When searching, foodies will have the option to select foods by ethnic type such as Greek, Chinese, Italian, Mexican, Caribbean, etc., or by category such as vegan, gluten free, comfort food, BBQ, desserts. Special Dietary meals can also be requested like Keto, Gluten-Free, Halal. Foodies will see a map indicating the location of those cooks and their specialties. As the number of cooks increases so will the options for different kinds of food.
                  <br/><br/>
                  Foodies will also set up a profile, including a payment method. Once that’s done, they’re ready to enter our kitchen for home cooked foods in their area.
                  <br/><br/>
                  Foodies may even want to follow a specific cook or want to remember a meal previously ordered on Nüri. A quick look in their Order history will provide that information. To search by location, use our map to find cooks wherever you are. If Nüri has a cook in that area, they will pop onto the map screen.
                  <br/><br/>
                  Nüri is an app that introduces passionate home cooks to people just as passionate about eating great foods, opening a world of delicious foods created right in your local area. Turn your joy and passion for cooking into a thriving business, Nüri will showcase your dishes to hungry foodies. We invite you to Enter our Kitchen!
                </p>
              )}
              {readMoreVisible === true && (
                <div style={{border: '0px solid black', }} onClick={() => setReadMoreVisible(false)}>
                  <p style={{color: '#4fb6e2', fontWeight: 'bold', fontSize: 24, textAlign: 'center', marginTop: 0, marginBottom: 0, cursor: 'pointer'}} >Read less...</p>
                </div>
              )}
              
            </div>
            
          </div> */}
          <div style={styles.infoPanelMobile}>
  <div style={styles.infoPanelOverlayMobile}>
    <section>
      <h1
        style={{
          textAlign: 'center',
          fontSize: 50,
          lineHeight: 1,
          marginBottom: 0,
          marginTop: 150,
          color: '#4fb6e2',
          fontWeight: 'bold',
        }}
      >
        Nüri is the official marketplace for home-cooked foods!
      </h1>
      <p
        style={{
          color: '#4d4d4d',
          fontSize: 24,
          textAlign: 'center',
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        The essence of the Nüri app is to bring together individuals who have a passion and gift for cooking from home and connect them with those who are just as passionate about eating home-cooked food.
      </p>
    </section>

    {!readMoreVisible && (
      <div
        style={{ border: '0px solid black', marginTop: 3 }}
        onClick={() => setReadMoreVisible(true)}
      >
        <p
          style={{
            color: '#4fb6e2',
            fontWeight: 'bold',
            fontSize: 24,
            textAlign: 'center',
            marginTop: 0,
            marginBottom: 0,
            cursor: 'pointer',
          }}
        >
          Read more...
        </p>
      </div>
    )}

    {readMoreVisible && (
      <article>
        <h2>Cooks can:</h2>
        <ul>
          <li>Turn their love for cooking into a business by selling homemade meals.</li>
          <li>Create a personal profile showcasing their culinary skills and photos of their dishes.</li>
          <li>Set hours of operation, prices, and prep times for their meals.</li>
          <li>Decide whether to offer pickup or delivery options (with a delivery fee).</li>
          <li>Track their sales and monitor income growth.</li>
          <li>Build a customer base with high-quality food and competitive pricing.</li>
          <li>Receive ratings from foodies, helping attract more customers.</li>
        </ul>

        <h2>Foodies can:</h2>
        <ul>
          <li>Discover and order authentic, home-cooked meals from local cooks.</li>
          <li>Search by cuisine type (e.g., Greek, Chinese, Vegan) or dietary needs (Keto, Gluten-Free, Halal).</li>
          <li>View cook profiles, complete with photos, descriptions, and customer ratings.</li>
          <li>Place orders through the app using a secure payment system—no cash required.</li>
          <li>Follow favorite cooks or reorder meals via order history.</li>
          <li>Use a map to find cooks and meals available nearby.</li>
        </ul>

        <h2>Nüri connects passionate home cooks with foodies eager for great, home-cooked meals.</h2>
        <p>
          Whether you're a cook looking to share your talent or a foodie searching for comfort food, Nüri is here to make those connections seamless and rewarding. Enter our Kitchen and start your culinary journey today!
        </p>
      </article>
    )}

    {readMoreVisible && (
      <div
        style={{ border: '0px solid black' }}
        onClick={() => setReadMoreVisible(false)}
      >
        <p
          style={{
            color: '#4fb6e2',
            fontWeight: 'bold',
            fontSize: 24,
            textAlign: 'center',
            marginTop: 0,
            marginBottom: 0,
            cursor: 'pointer',
          }}
        >
          Read less...
        </p>
      </div>
    )}
  </div>
</div>



        </div>
        {/* <header>
          <title>Nuri</title>
        </header>
        
        <body>
          <h1>This is a heading</h1>
          <p>This is a paragraph.</p>
        </body> */}
        <footer style={{
    position: 'relative',
    width: '100%',
    backgroundColor: '#81CFF0',
    color: 'white',
    textAlign: 'center',
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    marginTop: '50px' // Adjust this to add space between the content and footer
}}>
    <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '15px',
        paddingBottom: '10px'
    }}>
        <Link to="/TermsOfUse" style={{ textDecoration: 'none', color: 'white' }}>Terms of Use</Link>
        <Link to="/PrivacyPolicy" style={{ textDecoration: 'none', color: 'white' }}>Privacy Policy</Link>
        <Link to="/Contact" style={{ textDecoration: 'none', color: 'white' }}>Contact</Link>
        <Link to="/Support" style={{ textDecoration: 'none', color: 'white' }}>Support</Link>
    </div>
    <div style={{
        color: 'white'
    }}>
        <p style={{ margin: 0 }}>© {new Date().getFullYear()} NÜRI INC. <a href="https://nurieats.com" style={{ color: 'white', textDecoration: 'none' }}>nurieats.com</a></p>
    </div>
</footer>
      </div>
    )}
    
    </div>
    
    
  );
}

let styles = {
  // div: {
  //   fontFamily: 'Times',
  // },
  readMore: {
    border: '0px solid red',
    paddingLeft: '10%',
    paddingRight: '10%',
    // margin: 0
  },
  iphoneContainerMobile: {
    width: 'auto',
    height: '100%',
    // border: '3px solid black',
    display: 'flex',
    justifyContent: 'center'
  },
  infoPanelOverlayMobile: {
    padding: 50,
    paddingTop: 20,
    height: '100%',
    width: 'auto',
    // backgroundColor: 'red',
    // position: 'absolute',
    // border: '3px solid orange',
    background: 'rgb(255,255,255)',
    background: 'linear-gradient(180deg, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 65%)',
    //  display: 'flex',
    // justifyContent: 'center'
    
  },
  infoPanel: {
    width: 'auto',
    // height: 'auto',
    // border: '3px solid red',
    backgroundColor: 'white',
    padding: 0,
    // border: '3px solid red',
    height: 'auto', //1000
    // backgroundImage: `url(${backgroundImage2})`,
    // backgroundSize: 'cover',
    // backgroundPosition: '0px 150px',
    // backgroundRepeat: 'no-repeat',
   
  },
  infoPanelMobile: {
    width: 'auto',
    // height: 'auto',
    // border: '3px solid red',
    backgroundColor: 'white',
    padding: 0,
    // border: '3px solid red',
    height: 'auto', //1000
    // backgroundImage: `url(${backgroundImage2})`,
    // backgroundSize: 'cover',
    // backgroundPosition: '-400px 300px',
    // backgroundRepeat: 'no-repeat',
    // marginBottom: 10,
  },
  infoPanelOverlay: {
    padding: 50,
    paddingTop: 20,
    paddingBottom: 5,
    height: '100%',
    width: 'auto',
    // backgroundColor: 'red',
    // position: 'absolute',
    // border: '3px solid red',
    background: 'rgb(255,255,255)',
    background: 'linear-gradient(180deg, rgba(255,255,255,1) 25%, rgba(255,255,255,0) 40%)',
    //  display: 'flex',
    // justifyContent: 'center'
  },
  
  topPanelOverlap: {
    // height: 'auto',
    width: '100%',
    position: 'absolute',
    background: 'rgb(255,255,255)',
    background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)',
  },
  textWithShadow:{
    textShadowColor: 'rgba(0, 0, 0, 0.75)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 10,
    color: '#4d4d4d',
    fontSize: 40,
    // textAlign: 'center'
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    fontWeight: 'bold',
    letterSpacing: -2
},
textWithShadowMobile: {
  textShadow: '0px 5px 10px rgba(255, 255, 255, 1)',
  color: 'black',
  fontSize: '40px',
  fontWeight: 'bold',
  letterSpacing: '-1px',
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
},

textWhite:{
  textShadowColor: 'rgba(0, 0, 0, 0.75)',
  textShadowOffset: {width: -1, height: 1},
  textShadowRadius: 10,
  color: 'white',
  fontSize: 40,
  textAlign: 'center',
  position: 'absolute',
  marginRight: 2,
  marginTop: 30,
},
panelContainerMobile: {
  // border: '3px solid GREEN',
  // marginTop: 1000,
  height: 630,
  display:' flex',
  flexDirection: 'column',
},
panelContainer: {
  // border: '3px solid GREEN',
  height: 'auto',
  display:' flex',
  flexDirection: 'row',
  background: 'rgb(255,255,255)',
  background: 'linear-gradient(180deg, rgba(255,255,255,0) 80%, rgba(255,255,255,1) 100%)'
},

  leftPanel: {
    // border: '3px solid red',
    width: '50%',
    height: 'auto',
  },
  rightPanel: {
    // border: '3px solid black',
    width: '50%',
    height: '100%',
    background: 'rgb(255,255,255)',
    background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%)'
  },
  infoBar: {
    paddingTop: 100,
    background: 'rgb(129,207,240)',
    background: 'linear-gradient(0deg, rgba(129,207,240,1) 63%, rgba(34,193,195,0) 100%)'
  },
  bodyMobile: {
    // width: '50%',
    // height: '150%',
    // marginLeft: 'auto',
    // display: 'flex',
    display: 'flex',
    flexDirection: 'column',
    // border: '3px solid lightGreen',
    // backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: '-160px -100px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  //OLD
  // body: {
  //   // width: '50%',
  //   height: 'auto',
  //   // marginLeft: 'auto',
  //   // display: 'flex',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   // border: '3px solid lightGreen',
  //   backgroundImage: `url(${backgroundImage})`,
  //   backgroundPosition: '-160px -100px',
  //   backgroundRepeat: 'no-repeat',
  //   // backgroundSize: 'cover',
  //   overFlow: 'hidden',
  //   backgroundHeight: 100
  // },
  //NEW
  //BROWSER
  body: {
    // width: '50%',
    height: '100%',
    // marginLeft: 'auto',
    // display: 'flex',
    display: 'flex',
    flexDirection: 'column',
    // border: '3px solid lightGreen',
    backgroundImage: `url(${backgroundImage8})`,
    backgroundPosition: '-300px -220px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    overFlow: 'hidden',
    // backgroundHeight: 100
  },
 //OLD
  // topPanel: {
  //   // border: '3px solid red',
  //   // height: 200,
  //   backgroundImage: `url(${backgroundImage})`,
  //   backgroundSize: 'cover',
  //   backgroundPosition: '-10px -40px',
  //   backgroundRepeat: 'no-repeat',
  //   height: '85%',

  // },
  //NEW
  //MOBILE
  topPanel: {
    // border: '3px solid red',
    // height: 200,
    backgroundImage: `url(${backgroundImage8})`,
    backgroundSize: 'cover',
    backgroundPosition: '-10px -40px',
    backgroundRepeat: 'no-repeat',
    // height: '85%',
    height: 1000,

  },
  header: {
    width: '100%',
    height: 'auto',
    paddingBottom: 15,
    paddingTop: 15,
    // border: '3px solid blue',
    backgroundColor: '#81CFF0',
    paddingLeft: 35,
    display: 'flex',
    // alignSelf: 'center',

  },
  
  navBar: {
    // border: '3px solid purple',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#C981F0',
  },

  button: {
    
    backgroundColor: '#C981F0',
    border: '1px solid white',
    // borderTopWidth: 0,
    // borderLeftWidth: 0,
    // borderBottomWidth: 0,
    color: 'white',
    height: 35,
    width: 200,
    borderWidth: 1,
    fontSize: 10,
  },
  container: {
    width: '100%',
    // height: 1000,

    // height: null,
    overflow: 'hidden'
  },
  
  
  logo: {
    borderRadius: 50,
    shadowRadius: 10,
    marginTop: 50,
    // display: 'block',
    // display: 'flex',
    // flexDirection: 'row',
    // border: '3px solid black',
    height: 250,
    // border: '1px solid black',
    marginBottom: 10,
  },
  slogan: {
    // border: '1px solid red',
    height: 30,
  },
  logoMobile: {
    borderRadius: 45,
    shadowRadius: 10,
    marginTop: 120,
    // display: 'block',
    // display: 'flex',
    // flexDirection: 'row',
    // border: '3px solid black',
    height: 140,
    // border: '1px solid black',
    // marginBottom: -13,
    // marginbBottom: 0,
  },
  slogan: {
    // border: '1px solid red',
    height: 30,
  },
};

export default Home;
