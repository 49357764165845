// AnimatedIconButton.js
import "../App.css"; // Ensure your CSS is linked
import React from 'react';
import { Route, Link } from 'react-router-dom';

function AnimatedTextButton({ onClick, theme, text, style, isNavItem, navRouteName }) {
  const handleClick = () => {
    onClick();
  };

  return (
    <div>
      {!isNavItem && (
        <div
          onClick={handleClick}
          style={{
            ...style,
            width: 'auto',
            height: 45,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            // backgroundColor: theme === 'dark' ? '#5a5ac9' : '#ffffff',
            borderRadius: 15,
            // border: theme === 'dark' ? '2px solid #7b7bf7' : '2px solid #c4c4c4',
            paddingRight: 15,
            paddingLeft: 15,
          }}
        >
      
            <div
              className={'bold'}
              style={{
                userSelect: 'none',
                // color: theme === 'dark' ? '#ffffff' : '#2b2b2b',
                color: theme === 'dark' ? '#ffffff' : 'white',
                fontSize: 18, // Adjust text size as needed
                // textShadow: .5
              }}
            >
              {text}
            </div>
          
          

        </div>
      )
      ||
      (
        <Link 
        to={`/${navRouteName}`} 
        style={{ textDecoration: 'none' }} // Removes underline from the Link
      >
        <div
          onClick={handleClick}
          style={{
            ...style,
            width: 'auto',
            height: 45,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            // backgroundColor: theme === 'dark' ? '#5a5ac9' : '#ffffff',
            borderRadius: 15,
            // border: theme === 'dark' ? '2px solid #7b7bf7' : '2px solid #c4c4c4',
            paddingRight: 15,
            paddingLeft: 15,
          }}
        >
      
          <div
            className={'bold'}
            style={{
              userSelect: 'none',
              // color: theme === 'dark' ? '#ffffff' : '#2b2b2b',
              color: theme === 'dark' ? '#ffffff' : 'white',
              fontSize: 18, // Adjust text size as needed
              // textShadow: .5
            }}
          >
            {text}
          </div>
          
          

        </div>

      </Link>
        
      )}
      
    </div>
  );
}

export default AnimatedTextButton;