import React, { useState, useEffect } from 'react';
import logo from './assets/logo-icons/Icon-8.png';
// import backgroundImage from './assets/UI/Backgrounds/Welcome/Gluten-free@3x.jpg';
import './App.css';
import './index.css';
import { Route, Link } from 'react-router-dom';
import { IoMailOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { IoCalendarOutline } from "react-icons/io5";


function Contact(props) {

 

  useEffect(() => {
    props.setCurrentScreen('contact');
  },[]);


  const [button0Style, setButton0Style] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [button1Style, setButton1Style] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [buttonStyleNormal] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    borderWidth: 1,
    fontSize: 20,
  });

  const [buttonStyleHover] = useState({
    backgroundColor: 'white',
    border: '0px',
    // borderTopWidth: 0,
    // borderLeftWidth: 0,
    // borderBottomWidth: 0,
    color: '#81CFF0',
    height: 33,
    width: 200,
    borderWidth: 1,
    fontSize: 20,
  });

  function mouseHover(buttonIndex) {
    if(buttonIndex === 0) {
      setButton0Style(buttonStyleHover);
    }
    else if(buttonIndex === 1) {
      setButton1Style(buttonStyleHover);
    }
  };

  function mouseOut(buttonIndex) {
    if(buttonIndex === 0) {
      setButton0Style(buttonStyleNormal);
    }
    else if(buttonIndex === 1) {
      setButton1Style(buttonStyleNormal);
    }
  };

  return (
    <div style={styles.container}>
      <title>Nüri | Contact</title>
      
      <div style={{marginTop: 100, paddingBottom: 0, height: 'auto', width: '100%', border: '0px solid red', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
      <p style={{fontWeight: 'bold', marginBottom: 0, width: '50%', color: '#4d4d4d', fontSize: 26, textAlign: 'center', marginTop: 0, }} >
        {`Got any questions or concerns?`}
      </p>
      <p style={{marginBottom: 20, width: '50%', color: '#4d4d4d', fontSize: 20, textAlign: 'center', marginTop: 0, }} >
        {`Feel free to shoot us an email or call our customer support line.`}
      </p>
      <IoCalendarOutline style={{alignSelf: 'center', marginBottom: 5}} size={50} />
      <h5 style={{width: '50%', color: '#4d4d4d', fontSize: 20, textAlign: 'center', marginTop: 0, marginBottom: 0}} >
        {`Customer support hours`}
      </h5>
      <p style={{width: '50%', color: '#4d4d4d', fontSize: 20, textAlign: 'center', marginTop: 0, marginBottom: 20}} >
        {`Monday to Friday, 8am to 6pm.`}
      </p>
      <IoMailOutline style={{alignSelf: 'center'}} size={50} />
      <h3 style={{ color: '#4d4d4d', fontSize: 24, textAlign: 'center', marginTop: 0, marginBottom: 0 }} >
        
        {`Nüri Customer support e-mail`}
      </h3>
      <p style={{ color: '#4d4d4d', fontSize: 24, textAlign: 'center', marginTop: 0, marginBottom: 20 }} >
        <a href="mailto:support@nurieats.com" style={{ fontWeight: 'bold', color: '#C981F0', textDecoration: 'none' }}>
          {`support@nurieats.com`}
        </a>
      </p>
      <IoCallOutline style={{alignSelf: 'center'}} size={50} />
      <h3 style={{ color: '#4d4d4d', fontSize: 24, textAlign: 'center', marginTop: 0, marginBottom: 0 }} >
        {`Nüri Customer support toll-free hotline`}
      </h3>
      <p style={{ color: '#4d4d4d', fontSize: 24, textAlign: 'center', marginTop: 0, marginBottom: 0 }} >
        <a href="tel:+18776380995" style={{ fontWeight: 'bold', color: '#C981F0', textDecoration: 'none' }}>
          {`1-(877)-638-0995`}
        </a>
        
      </p>
      
      </div>
      <footer style={{
  width: '100%',
  backgroundColor: '#81CFF0',
  color: 'white',
  textAlign: 'center',
  padding: '20px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '14px',
  marginTop: 'auto', // Automatically pushes footer to bottom of container
  position: 'relative'
}}>
  <div style={{
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '15px',
    paddingBottom: '10px'
  }}>
    <Link to="/TermsOfUse" style={{ textDecoration: 'none', color: 'white' }}>Terms of Use</Link>
    <Link to="/PrivacyPolicy" style={{ textDecoration: 'none', color: 'white' }}>Privacy Policy</Link>
    <Link to="/Contact" style={{ textDecoration: 'none', color: 'white' }}>Contact</Link>
    <Link to="/Support" style={{ textDecoration: 'none', color: 'white' }}>Support</Link>
  </div>
  <div style={{ color: 'white' }}>
    <p style={{ margin: 0 }}>© {new Date().getFullYear()} NÜRI INC. <a href="https://nurieats.com" style={{ color: 'white', textDecoration: 'none' }}>nurieats.com</a></p>
  </div>
</footer>
    </div>
  );
}

let styles = {

  container: {
    width: '100%',
    minHeight: '100vh', // Ensures the container fills the viewport height
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // Pushes footer to bottom if content is short
  },

};

export default Contact;
